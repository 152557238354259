import { ForwardedRef, ReactNode, forwardRef, useCallback } from 'react';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';

interface TabButtonStyleProps {
	isActive: boolean;
	isDisabled: boolean;
	isInvalid: boolean;
}

const tabButtonStyle = prepareStyle<TabButtonStyleProps>(
	(utils, { isActive, isDisabled, isInvalid }) => ({
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: 0,
		color: utils.colors.gray700,
		display: 'inline-flex',
		flexGrow: 0,
		flexShrink: 0,
		paddingBottom: '1.5rem',
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: 0,
		position: 'relative',
		userSelect: 'none',
		selectors: {
			':not(:first-child)': {
				marginInlineStart: utils.spacings.lg,
			},
		},
		extend: [
			{
				condition: isActive,
				style: {
					fontWeight: utils.fontWeights.extraBold,
				},
			},
			{
				condition: !isActive && !isDisabled,
				style: {
					cursor: 'pointer',
					selectors: {
						':hover': {
							color: utils.colors.primary,
						},
						':hover svg': {
							fill: utils.colors.primary,
						},
					},
				},
			},
			{
				condition: isDisabled,
				style: {
					cursor: 'not-allowed',
					opacity: 0.3,
				},
			},
			{
				condition: isInvalid,
				style: {
					color: utils.colors.danger,
				},
			},
		],
	})
);

export interface TabButtonProps {
	children: ReactNode;
	customStyle?: Style<TabButtonStyleProps>;
	icon?: ReactNode;
	isActive: boolean;
	isDisabled: boolean;
	isInvalid: boolean;
	onClick?: () => void;
}

export const TabButton = forwardRef(
	(
		{
			children,
			customStyle,
			icon = null,
			isActive,
			isDisabled,
			isInvalid,
			onClick,
		}: TabButtonProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { applyStyle } = useStyles();

		const handleTabButtonClick = useCallback(() => {
			if (!isDisabled) {
				onClick?.();
			}
		}, [isDisabled, onClick]);

		return (
			<button
				aria-selected={isActive}
				className={applyStyle([tabButtonStyle, customStyle], { isActive, isDisabled, isInvalid })}
				disabled={isDisabled}
				onClick={handleTabButtonClick}
				ref={ref}
				role="tab"
				type="button"
			>
				{children}
				{icon}
			</button>
		);
	}
);

TabButton.displayName = 'TabButton';
