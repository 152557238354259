import { noop } from 'ramda-extension';
import { useCallback } from 'react';
import { useStore } from 'react-redux';

import { getIsTokenExpired } from './utils';

export interface UseResolveAccessTokenParams {
	fetchAccessToken: (refreshToken: string) => any;
	selectAccessToken: (state: any) => string | null;
	selectIsAuthenticating: (state: any) => boolean;
	selectRefreshToken: (state: any) => string | null;
}

export const useResolveAccessToken = ({
	fetchAccessToken,
	selectAccessToken,
	selectIsAuthenticating,
	selectRefreshToken,
}: UseResolveAccessTokenParams) => {
	const store = useStore();

	return useCallback(
		() =>
			new Promise<string>((resolve, reject) => {
				let unsubscribe: ReturnType<typeof store.subscribe> = noop;

				try {
					const accessToken = selectAccessToken(store.getState());

					if (!accessToken || getIsTokenExpired(accessToken)) {
						unsubscribe = store.subscribe(() => {
							const nextAccessToken = selectAccessToken(store.getState());

							if (nextAccessToken && nextAccessToken !== accessToken) {
								resolve(nextAccessToken);
								unsubscribe();
							}
						});

						if (!selectIsAuthenticating(store.getState())) {
							const refreshToken = selectRefreshToken(store.getState());

							if (refreshToken) {
								store.dispatch(fetchAccessToken(refreshToken));
							}
						}
					} else {
						resolve(accessToken);
					}
				} catch (error) {
					reject(error);
					unsubscribe();
				}
			}),
		[store]
	);
};
