import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@ci/atoms';
import { useTranslationsFetchEffect } from '@ci/message';
import { prepareStyle, Style, useStyles } from '@ci/styles';

import { CollapsibleControls, CollapsibleControlsContext } from '../contexts';
import { COLLAPSIBLE_CONTROLS_DOM_ATTRIBUTE } from '../constants';
import { m } from '../messages';

const collapsibleStyle = prepareStyle<{ hasShadow: boolean }>((utils, { hasShadow }) => ({
	backgroundColor: utils.colors.white,
	borderRadius: utils.borders.radii.basic,
	extend: {
		condition: hasShadow,
		style: {
			boxShadow: utils.boxShadows.basic,
		},
	},
}));

const collapsibleHeaderStyle = prepareStyle<{
	hasContentDivider: boolean;
	hasLabel: boolean;
	isExpanded: boolean;
}>((utils, { hasLabel, isExpanded, hasContentDivider }) => ({
	alignItems: 'center',
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'space-between',
	padding: utils.spacings.sm,
	paddingInlineStart: utils.spacings.md,
	selectors: {
		[utils.breakpoints.xl]: {
			paddingInlineStart: utils.spacings.lg,
		},
	},
	extend: [
		{
			condition: hasLabel,
			style: {
				flexDirection: 'column-reverse',
				flexWrap: 'wrap',
				selectors: {
					[utils.breakpoints.lg]: {
						flexDirection: 'row',
						flexWrap: 'nowrap',
					},
				},
			},
		},
		{
			condition: isExpanded && hasContentDivider,
			style: {
				borderBottomColor: utils.colors.gray200,
				borderBottomStyle: 'solid',
				borderBottomWidth: utils.borders.widths.sm,
				marginBottom: utils.spacings.sm,
			},
		},
	],
}));

const collapsibleHeaderButtonStyle = prepareStyle(utils => ({
	color: utils.colors.gray700,
	fontWeight: utils.fontWeights.semiBold,
	marginInlineStart: 0,
	minWidth: 'auto',
	paddingInlineEnd: utils.spacings.sm,
	paddingInlineStart: utils.spacings.sm,
	selectors: {
		':hover, :focus': {
			borderColor: 'transparent',
			color: utils.colors.gray700,
		},
		[utils.breakpoints.lg]: {
			marginInlineStart: 'auto',
		},
	},
}));

const collapsibleContentStyle = prepareStyle<{
	isExpanded: boolean;
}>((utils, { isExpanded }) => ({
	height: 0,
	opacity: 0,
	overflow: 'hidden',
	paddingLeft: utils.spacings.md,
	paddingRight: utils.spacings.md,
	transitionDuration: utils.transitions.speeds.faster,
	transitionProperty: 'opacity',
	transitionTimingFunction: utils.transitions.easing,
	selectors: {
		[utils.breakpoints.xl]: {
			paddingLeft: utils.spacings.lg,
			paddingRight: utils.spacings.lg,
		},
	},
	extend: {
		condition: isExpanded,
		style: {
			height: 'auto',
			opacity: 1,
			overflow: 'visible',
			paddingBottom: utils.spacings.md,
			paddingTop: utils.spacings.sm,
			selectors: {
				[utils.breakpoints.xl]: {
					paddingBottom: utils.spacings.lg,
				},
			},
		},
	},
}));

export interface CollapsibleProps {
	children: ReactNode;
	collapsedLabel?: ReactNode;
	customStyle?: Style;
	defaultIsExpanded?: boolean;
	expandedLabel?: ReactNode;
	hasContentDivider?: boolean;
	hasShadow?: boolean;
	isExpanded?: boolean;
	onToggle?: (isExpanded: boolean) => void;
	status?: ReactNode;
}

export const Collapsible = ({
	children,
	collapsedLabel,
	customStyle,
	defaultIsExpanded,
	expandedLabel,
	hasContentDivider = false,
	hasShadow = true,
	isExpanded: isExpandedProp,
	onToggle,
	status,
}: CollapsibleProps) => {
	const { applyStyle } = useStyles();
	const intl = useIntl();

	useTranslationsFetchEffect([m.collapse.id, m.expand.id]);

	const [localIsExpanded, setLocalIsExpanded] = useState(
		isExpandedProp ?? defaultIsExpanded ?? false
	);

	const hasLabel = Boolean(collapsedLabel || expandedLabel);
	const isExpanded = isExpandedProp === undefined ? localIsExpanded : isExpandedProp;
	const icon = isExpanded ? 'caretUp' : 'caretDown';
	const iconTitle = intl.formatMessage(isExpanded ? m.collapse : m.expand);
	const label = isExpanded ? expandedLabel : collapsedLabel;

	const handleClick = useCallback(() => {
		setLocalIsExpanded(!isExpanded);
		onToggle?.(!isExpanded);
	}, [isExpanded, onToggle]);

	const expand = useCallback(() => {
		setLocalIsExpanded(true);
		onToggle?.(true);
	}, [onToggle]);

	const collapsibleControls: CollapsibleControls = useMemo(
		() => ({ expand, isExpanded }),
		[expand, isExpanded]
	);

	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current[COLLAPSIBLE_CONTROLS_DOM_ATTRIBUTE] = collapsibleControls;
		}
	}, [collapsibleControls]);

	return (
		<CollapsibleControlsContext.Provider value={collapsibleControls}>
			<div className={applyStyle([collapsibleStyle, customStyle], { hasShadow })}>
				<div
					role="button"
					className={applyStyle(collapsibleHeaderStyle, {
						hasLabel,
						isExpanded,
						hasContentDivider,
					})}
					onClick={handleClick}
				>
					{status}
					<Button
						icon={icon}
						iconProps={{ title: iconTitle }}
						variant="subtle"
						role="presentation"
						iconPlacement="end"
						customStyle={collapsibleHeaderButtonStyle}
					>
						{label}
					</Button>
				</div>
				<div
					ref={contentRef}
					role="region"
					aria-expanded={isExpanded}
					className={applyStyle(collapsibleContentStyle, { isExpanded })}
				>
					{children}
				</div>
			</div>
		</CollapsibleControlsContext.Provider>
	);
};
