import { not } from 'ramda';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { TranslationModeContext } from '../contexts';

const useIsTranslationModeEnabled = (delay: number) => {
	const [isTranslationModeEnabled, setIsTranslationModeEnabled] = useState(false);
	const keyDownTimeoutIdRef = useRef<number | null>(null);

	const clearKeyDownTimeout = useCallback(() => {
		if (keyDownTimeoutIdRef.current !== null) {
			window.clearTimeout(keyDownTimeoutIdRef.current);
			keyDownTimeoutIdRef.current = null;
		}
	}, []);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.ctrlKey && event.key.toLowerCase() === 'i') {
				if (!keyDownTimeoutIdRef.current) {
					keyDownTimeoutIdRef.current = window.setTimeout(
						() => setIsTranslationModeEnabled(not),
						delay
					);
				}
			} else {
				clearKeyDownTimeout();
			}
		},
		[delay, clearKeyDownTimeout]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown, false);
		window.addEventListener('keyup', clearKeyDownTimeout, false);

		return () => {
			window.removeEventListener('keydown', handleKeyDown, false);
			window.removeEventListener('keyup', clearKeyDownTimeout, false);
		};
	}, [clearKeyDownTimeout, handleKeyDown]);

	return isTranslationModeEnabled;
};

export interface TranslationModeProviderProps {
	children: ReactNode;
	delay?: number;
}

export const TranslationModeProvider = ({
	children,
	delay = 3000,
}: TranslationModeProviderProps) => {
	const isTranslationModeEnabled = useIsTranslationModeEnabled(delay);

	return (
		<TranslationModeContext.Provider value={isTranslationModeEnabled}>
			{children}
		</TranslationModeContext.Provider>
	);
};
