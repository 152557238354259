import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { dec, inc } from 'ramda';
import { cx, noop } from 'ramda-extension';
import {
	MouseEvent,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { queryBottomOverlay } from '../utils';

/**
 * Use this CSS class to conditionally apply `margin-right` when a backdrop is active.
 *
 * This is useful to avoid elements shifting horizontally when the scrollbar disappears as a result
 * of `overflow: hidden` being applied to the document body.
 */
export const BACKDROP_MARGIN_RIGHT_CLASS = 'BackdropMarginRight';

interface BackdropStyleProps {
	zIndex?: number;
}

const backdropStyle = prepareStyle<BackdropStyleProps>((utils, { zIndex }) => ({
	alignItems: 'center',
	backgroundColor: utils.colors.backdrop,
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	justifyContent: 'center',
	left: 0,
	padding: `${utils.spacings.lg} ${utils.spacings.md}`,
	position: 'fixed',
	top: 0,
	width: '100%',
	zIndex,

	selectors: {
		[utils.breakpoints.sm]: {
			padding: utils.spacings.md,
		},
	},
}));

interface BackdropScrollingCallbackContextValue {
	onMount: () => void;
	onUnmount: () => void;
}

const BackdropScrollingCallbackContext = createContext<BackdropScrollingCallbackContextValue>({
	onMount: noop,
	onUnmount: noop,
});

const BackdropScrollingCounterContext = createContext<number>(0);

export interface BackdropScrollingProviderProps {
	children: ReactNode;
}

export const BackdropScrollingProvider = ({ children }: BackdropScrollingProviderProps) => {
	const [counter, setCounter] = useState(0);
	const isAnyBackdropMounted = counter > 0;

	const callbackContextValue = useMemo(
		() => ({
			onMount: () => setCounter(inc),
			onUnmount: () => setCounter(dec),
		}),
		[]
	);

	useLayoutEffect(() => {
		if (isAnyBackdropMounted) {
			// NOTE: We're not using `getScrollbarWidth()` because it doesn't consider whether
			// the scrollbar is actually visible. We don't need to update the body padding and
			// bottom overlay offset if there's no scrollbar to remove.
			const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

			const updateHorizontalOffsets = (offset: string) => {
				document.body.style.paddingRight = offset;

				// TODO: Remove this once all bottom overlays use `BACKDROP_MARGIN_RIGHT_CLASS`.
				const bottomOverlay = queryBottomOverlay();

				if (bottomOverlay) {
					bottomOverlay.style.right = offset;
				}

				document
					.querySelectorAll<HTMLElement>(`.${BACKDROP_MARGIN_RIGHT_CLASS}`)
					.forEach(element => {
						element.style.marginRight = offset;
					});
			};

			document.body.style.overflow = 'hidden';

			if (scrollbarWidth > 0) {
				updateHorizontalOffsets(`${scrollbarWidth}px`);
			}

			return () => {
				document.body.style.overflow = '';

				if (scrollbarWidth > 0) {
					updateHorizontalOffsets('');
				}
			};
		}

		return undefined;
	}, [isAnyBackdropMounted]);

	return (
		<BackdropScrollingCallbackContext.Provider value={callbackContextValue}>
			<BackdropScrollingCounterContext.Provider value={counter}>
				{children}
			</BackdropScrollingCounterContext.Provider>
		</BackdropScrollingCallbackContext.Provider>
	);
};

export interface BackdropProps {
	children?: ReactNode;
	className?: string;
	/**
	 * Can be used to make the backdrop behave like a simple `div` element. Conditionally wrapping
	 * the content in a backdrop is not a viable solution due to React reconciliation.
	 */
	isDisabled?: boolean;
	/**
	 * Can be used to indicate that the backdrop is hidden through external means, for example
	 * using a transition class. Internally, this affects disabling `document.body` scrolling.
	 */
	isExternallyHidden?: boolean;
	onClick?: (event?: MouseEvent<HTMLDivElement>) => void;
	zIndex?: number;
}

export const Backdrop = ({
	className,
	children,
	isDisabled = false,
	isExternallyHidden = false,
	onClick = noop,
	zIndex,
}: BackdropProps) => {
	const { applyStyle } = useStyles();
	const { onMount, onUnmount } = useContext(BackdropScrollingCallbackContext);

	useEffect(() => {
		if (!isDisabled && !isExternallyHidden) {
			onMount();

			return () => onUnmount();
		}

		return undefined;
	}, [isDisabled, isExternallyHidden, onMount, onUnmount]);

	if (isDisabled) {
		return <div>{children}</div>;
	}

	return (
		<div
			data-testid="Backdrop"
			className={cx(applyStyle(backdropStyle, { zIndex }), className)}
			onClick={onClick}
		>
			{children}
		</div>
	);
};
