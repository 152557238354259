import { useCallback } from 'react';
import { isDirty, isPristine, isSubmitting, isValid } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { o } from 'ramda';
import { defaultNamespace } from 'redux-syringe';

import { resetForm, submitForm } from './duck';

const useNamespacedDispatch = namespace => {
	const dispatch = useDispatch();

	return o(dispatch, defaultNamespace(namespace));
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const useFormSelector = namespace => selector => useSelector(selector(namespace));

export const useFormControls = namespace => {
	const dispatch = useNamespacedDispatch(namespace);
	const useNamespacedSelector = useFormSelector(namespace);

	return {
		reset: useCallback(() => dispatch(resetForm()), [dispatch]),
		submit: useCallback(() => dispatch(submitForm()), [dispatch]),
		isSubmitting: useNamespacedSelector(isSubmitting),
		isValid: useNamespacedSelector(isValid),
		isPristine: useNamespacedSelector(isPristine),
		isDirty: useNamespacedSelector(isDirty),
	};
};
