import { ReactNode, createContext, forwardRef, useContext } from 'react';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { Breakpoint } from '@creditinfo-ui/fela';
import { Spacing, VerticalSpacing, resolveVerticalMargin } from '@creditinfo-ui/atoms';

type MobileLayout = 'column' | 'column-reverse' | 'row';

export interface ButtonsProps {
	buttons: ReactNode[];
	customStyle?: Style<ButtonsStyleProps>;
	isCentered?: boolean;
	mobileLayout?: MobileLayout;
	mobileLayoutBreakpoint?: Breakpoint;
	verticalMargin?: VerticalSpacing;
}

export interface ButtonsStyleProps {
	isCentered: boolean;
	marginBottom: Spacing;
	marginTop: Spacing;
	mobileLayout: MobileLayout;
	mobileLayoutBreakpoint: Breakpoint;
}

export const ButtonsDefaultMobileLayoutContext = createContext<MobileLayout>('column');

const buttonsStyle = prepareStyle<ButtonsStyleProps>(
	(utils, { isCentered, mobileLayout, mobileLayoutBreakpoint, marginBottom, marginTop }) => ({
		alignItems: 'center',
		display: 'flex',
		flexDirection: mobileLayout,
		selectors: {
			[utils.breakpoints[mobileLayoutBreakpoint]]: {
				flexDirection: 'row',
			},
			[utils.media.print]: {
				display: 'none',
			},
		},
		extend: [
			{
				condition: isCentered,
				style: {
					justifyContent: 'center',
				},
			},
			{
				condition: marginBottom !== 'none',
				style: {
					marginBottom: utils.spacings[marginBottom],
				},
			},
			{
				condition: marginTop !== 'none',
				style: {
					marginTop: utils.spacings[marginTop],
				},
			},
		],
	})
);

const buttonWrapperStyle = prepareStyle<{
	mobileLayout: MobileLayout;
	mobileLayoutBreakpoint: Breakpoint;
}>((utils, { mobileLayout, mobileLayoutBreakpoint }) => {
	const rowLayoutStyleObject = {
		flexGrow: 0,
		width: 'auto',
		selectors: {
			':not(:first-child)': {
				marginInlineStart: utils.spacings.sm,
				marginTop: 0,
			},
			':not(:last-child)': {
				marginTop: 0,
			},
		},
	};

	return {
		extend: [
			{
				condition: mobileLayout === 'row',
				style: rowLayoutStyleObject,
			},
			{
				condition: mobileLayout === 'column' || mobileLayout === 'column-reverse',
				style: {
					flexGrow: 1,
					width: '100%',

					selectors: {
						'& > *': {
							width: '100%',
						},
						[mobileLayout === 'column-reverse' ? ':not(:last-child)' : ':not(:first-child)']: {
							marginTop: utils.spacings.sm,
						},
						[utils.breakpoints[mobileLayoutBreakpoint]]: rowLayoutStyleObject,
					},
				},
			},
		],
	};
});

export const Buttons = forwardRef<HTMLDivElement, ButtonsProps>(
	(
		{
			buttons,
			customStyle,
			isCentered = false,
			mobileLayout: mobileLayoutProp,
			mobileLayoutBreakpoint = 'sm',
			verticalMargin = 'none',
		},
		ref
	) => {
		const { applyStyle } = useStyles();
		const defaultMobileLayout = useContext(ButtonsDefaultMobileLayoutContext);
		const mobileLayout = mobileLayoutProp ?? defaultMobileLayout;
		const { marginBottom, marginTop } = resolveVerticalMargin(verticalMargin);

		return (
			<div
				className={applyStyle([buttonsStyle, customStyle], {
					isCentered,
					mobileLayout,
					mobileLayoutBreakpoint,
					marginBottom,
					marginTop,
				})}
				ref={ref}
			>
				{buttons.filter(Boolean).map((button, buttonIndex) => (
					<div
						className={applyStyle(buttonWrapperStyle, {
							mobileLayout,
							mobileLayoutBreakpoint,
						})}
						key={buttonIndex}
					>
						{button}
					</div>
				))}
			</div>
		);
	}
);
