import { makeFormatter } from 'afformative';
import moment from 'moment';
import { ReactNode } from 'react';

export type FormatterTimeZoneMode = 'convertToLocalTime' | 'ignoreUtcOffset';

let formatterTimeZoneMode: FormatterTimeZoneMode = 'ignoreUtcOffset';

export const configureFormatterTimeZoneMode = (mode: FormatterTimeZoneMode): void => {
	formatterTimeZoneMode = mode;
};

export const makeMomentFormatter = (format: string, displayName = 'momentFormatter') =>
	makeFormatter<moment.MomentInput, ReactNode, string>(
		value =>
			value
				? formatterTimeZoneMode === 'ignoreUtcOffset'
					? moment.parseZone(value).format(format)
					: moment(value).format(format)
				: '',
		{ displayName }
	);

export const dateTimeFormatter = makeMomentFormatter('lll', 'dateTimeFormatter');
export const dateFormatter = makeMomentFormatter('l', 'dateFormatter');

export const quarterFormatter = makeFormatter<moment.MomentInput, string>(
	value => {
		const date = moment.parseZone(value);

		return `${date.format('YYYY')}-Q${date.format('Q')}`;
	},
	{ displayName: 'quarterFormatter' }
);
