import { compose, splitEvery, replace, unless, trim } from 'ramda';
import { isNilOrEmpty, padLeftUntil } from 'ramda-extension';
import { joinWithNonBreakingSpace } from './joinWithNonBreakingSpace';
import convertToString from './convertToString';

const formatPhoneValue = compose(
	replace(/^\+/, `\u200e+`),
	replace('+\u00a0', '+'),
	trim,
	joinWithNonBreakingSpace,
	splitEvery(3),
	padLeftUntil(value => value.length % 3 === 0, ' '),
	convertToString
);

export const formatPhone = unless(isNilOrEmpty, formatPhoneValue);
