import { __, prop } from 'ramda';

// NOTE: All variables must be listed here so we can properly override them via `window.env`.
// WARNING: The following variables will be a part of the source code!
const baseConfig = {
	// NOTE: Fallback to `GATSBY_` is here so people don't need to change their old `.env.override` files
	// or `env.js` files in production and so they can still search by the old environment variable name.
	BROWSER_DISABLE_TRANSLATION_WARNINGS:
		process.env.BROWSER_DISABLE_TRANSLATION_WARNINGS ??
		process.env.GATSBY_DISABLE_TRANSLATION_WARNINGS,
	BROWSER_SESSION_TIMEOUT:
		process.env.BROWSER_SESSION_TIMEOUT ?? process.env.GATSBY_SESSION_TIMEOUT,
	BROWSER_HASHIDS_SALT: process.env.BROWSER_HASHIDS_SALT ?? process.env.GATSBY_HASHIDS_SALT,
	BROWSER_ATTACHMENT_STATUS_POLL_DELAY:
		process.env.BROWSER_ATTACHMENT_STATUS_POLL_DELAY ??
		process.env.BROWSER_CB5_ATTACHMENT_STATUS_POLL_DELAY ??
		process.env.GATSBY_CB5_ATTACHMENT_STATUS_POLL_DELAY,
	BROWSER_ATTACHMENT_STATUS_POLL_INTERVAL:
		process.env.BROWSER_ATTACHMENT_STATUS_POLL_INTERVAL ??
		process.env.BROWSER_CB5_ATTACHMENT_STATUS_POLL_INTERVAL ??
		process.env.GATSBY_CB5_ATTACHMENT_STATUS_POLL_INTERVAL,
	BROWSER_ATTACHMENT_STATUS_POLL_TIMEOUT:
		process.env.BROWSER_ATTACHMENT_STATUS_POLL_TIMEOUT ??
		process.env.BROWSER_CB5_ATTACHMENT_STATUS_POLL_TIMEOUT ??
		process.env.GATSBY_CB5_ATTACHMENT_STATUS_POLL_TIMEOUT,
	BROWSER_CI_DEVTOOLS_ENABLED:
		process.env.BROWSER_CI_DEVTOOLS_ENABLED ??
		process.env.GATSBY_CI_DEVTOOLS_ENABLED ??
		process.env.BROWSER_CB5_DEVTOOLS_ENABLED ??
		process.env.GATSBY_CB5_DEVTOOLS_ENABLED,
};

declare global {
	interface Window {
		env?: Partial<typeof baseConfig>;
	}
}

const runtimeConfig = typeof window !== 'undefined' ? { ...baseConfig, ...window.env } : baseConfig;

export const getEnvironmentVariable = prop(__, runtimeConfig);
