import { Button } from '@creditinfo-ui/atoms';
import { Message, MessageValues } from '@creditinfo-ui/messages';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

import { m } from './messages';
import { ToastContent } from './types';

const expandableContentStyle = prepareStyle(() => ({
	maxHeight: '15rem',
	overflow: 'hidden',
	position: 'relative',

	selectors: {
		'::after': {
			background: 'linear-gradient(transparent 10rem, white 100%)',
			content: "''",
			height: '100%',
			left: 0,
			pointerEvents: 'none',
			position: 'absolute',
			top: 0,
			width: '100%',
		},
	},
}));

const richContentStyle = prepareStyle(utils => ({
	selectors: {
		'& h1, & h2, & h3': {
			fontSize: utils.fontSizes.base,
		},
		'& pre': {
			whiteSpace: 'pre-wrap',
		},
		'& > :first-child': {
			marginTop: 0,
		},
		'& > :last-child': {
			marginBottom: 0,
		},
	},
}));

const rootStyle = prepareStyle(utils => ({
	flexGrow: 1,
	// HACK: Line height is set so the text is inline with the toast icon. Also, the same line
	// height is used by the announcement modal.
	lineHeight: 2,
	maxWidth: '100%',
	paddingInlineStart: utils.spacings.sm,
}));

const readMoreStyle = prepareStyle(utils => ({
	marginTop: utils.spacings.sm,
	textAlign: 'end',
}));

export interface ToastBodyProps {
	content: ToastContent;
	hasRichContent: boolean;
	onExpand?: () => void;
	values?: MessageValues;
}

export const ToastBody = ({ content, hasRichContent, onExpand, values }: ToastBodyProps) => {
	const { applyStyle } = useStyles();

	const contentClassName = applyStyle([
		// NOTE: `richContentStyle` does not need to be applied conditionally.
		richContentStyle,
		onExpand ? expandableContentStyle : null,
	]);

	return (
		<div className={applyStyle(rootStyle)}>
			{typeof content === 'string' && hasRichContent ? (
				// eslint-disable-next-line react/no-danger
				<div className={contentClassName} dangerouslySetInnerHTML={{ __html: content }} />
			) : (
				<div className={contentClassName}>
					{typeof content === 'string' ? content : <Message {...content} values={values} />}
				</div>
			)}
			{onExpand && (
				<div className={applyStyle(readMoreStyle)}>
					<Button onClick={onExpand} size="small" variant="subtle">
						<Message {...m.readMore} />
					</Button>
				</div>
			)}
		</div>
	);
};
