import {
	compose,
	fromPairs,
	reduce,
	split,
	toPairs,
	o,
	head,
	map,
	startsWith,
	any,
	test,
} from 'ramda';
import { flattenObject } from '@ci/utils';
import { dotPath } from 'ramda-extension';
import { MessageDescriptor } from 'react-intl';

const messageRegExp = /(?:\.validationErrors\.[0-9]+)?\.message\./;

const isMessageFieldName = test(messageRegExp);
const getSanitizedFieldName = o(head, split(messageRegExp));

export const prepareFormErrors = (
	errors: Record<string, any>
): Record<string, MessageDescriptor | string> =>
	(compose as any)(
		fromPairs,
		errorPairs => {
			const messageErrorPairs = reduce<any, any>(
				// NOTE: `.message.` logic is necessary because `flattenObject` doesn't care if we're
				// flattening a nested field or a message, e.g. `generalInfo.expirationDate.message.id`.
				(pairs, [name]) =>
					isMessageFieldName(name)
						? [
								...pairs,
								[getSanitizedFieldName(name), dotPath(getSanitizedFieldName(name), errors)],
						  ]
						: pairs,
				[],
				errorPairs
			);

			const messageErrorFieldNames = map<any, string>(head, messageErrorPairs);

			const stringErrorPairs = reduce<any, any>(
				(pairs, [name, string]) =>
					any(
						messageErrorFieldName => startsWith(messageErrorFieldName, name),
						messageErrorFieldNames
					)
						? pairs
						: [...pairs, [name, string]],
				[],
				errorPairs
			);

			return [...messageErrorPairs, ...stringErrorPairs];
		},
		toPairs,
		object => flattenObject(object)
	)(errors);
