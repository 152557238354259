import { Button, Text } from '@creditinfo-ui/atoms';
import { Buttons } from '@creditinfo-ui/layout';
import { Message } from '@creditinfo-ui/messages';
import { last, pipe } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';
import { withMiddleware, withReducers } from 'redux-syringe';
import {
	CONFIRM_MODAL_ID_PREFIX,
	ConfirmPayload,
	ModalStackItem,
	middleware,
	reducer,
	resolveConfirm,
	selectModalStack,
} from '../duck';
import { useModal } from '../hooks';
import { m } from '../messages';
import { createModalInstance } from '../utils';
import { Modal } from './Modal';
import { BasicStaticModal } from './StaticModal/BasicStaticModal';

const renderMessage = (message: MessageDescriptor | string | undefined) => {
	if (!message) {
		return null;
	}

	return typeof message === 'string' ? message : <Message {...message} />;
};

const IntrinsicModal = ({ id, payload }: ModalStackItem<ConfirmPayload>) => {
	const modalInstance = useMemo(() => createModalInstance<ConfirmPayload>(id), [id]);
	const { hide } = useModal(modalInstance);
	const { hasCancelButton = true, messages, shouldHideModalWhenConfirmed = true } = payload ?? {};
	const [isConfirmButtonBusy, setIsConfirmButtonBusy] = useState(false);

	const handleCancelButtonClick = useCallback(() => {
		resolveConfirm(id, { hasCanceled: true, hasConfirmed: false, modalInstance });
		hide();
	}, [hide, id, modalInstance]);

	const handleConfirmButtonClick = useCallback(() => {
		resolveConfirm(id, { hasCanceled: false, hasConfirmed: true, modalInstance });

		if (shouldHideModalWhenConfirmed) {
			hide();
		} else {
			setIsConfirmButtonBusy(true);
		}
	}, [hide, id, modalInstance, shouldHideModalWhenConfirmed]);

	const handleHide = useCallback(() => {
		setIsConfirmButtonBusy(false);
		resolveConfirm(id, { hasCanceled: false, hasConfirmed: false, modalInstance });
	}, [id, modalInstance]);

	return (
		<Modal
			instance={modalInstance}
			onHide={handleHide}
			renderPresenter={BasicStaticModal}
			presenterProps={{
				title: renderMessage(messages?.title),
				isTitleCentered: true,
				footer: (
					<Buttons
						isCentered
						mobileLayout="row"
						buttons={[
							hasCancelButton && (
								<Button
									onClick={handleCancelButtonClick}
									variant={['secondary', { isOutlined: true }]}
								>
									{renderMessage(messages?.cancel ?? m.cancel)}
								</Button>
							),
							<Button
								isBusy={isConfirmButtonBusy}
								onClick={handleConfirmButtonClick}
								variant="primary"
							>
								{renderMessage(messages?.confirm ?? m.confirm)}
							</Button>,
						]}
					/>
				),
			}}
		>
			<Text align="center" tag="p">
				{renderMessage(messages?.description)}
			</Text>
		</Modal>
	);
};

const PureIntrinsicModals = () => {
	const modalStack = useSelector(selectModalStack);
	const [modalStackState, setModalStackState] = useState(modalStack);

	useEffect(() => {
		if (last(modalStack)?.id.startsWith(CONFIRM_MODAL_ID_PREFIX)) {
			setModalStackState(modalStack);
		}
	}, [modalStack]);

	return (
		<>
			{modalStackState
				.filter((modalStackItem): modalStackItem is ModalStackItem<ConfirmPayload> =>
					modalStackItem.id.startsWith(CONFIRM_MODAL_ID_PREFIX)
				)
				.map(modalStackItem => (
					<IntrinsicModal {...modalStackItem} key={modalStackItem.id} />
				))}
		</>
	);
};

export const IntrinsicModals = pipe(
	withMiddleware(middleware),
	withReducers({ modalStack: reducer })
)(PureIntrinsicModals);
