import {
	FocusEventHandler,
	ForwardedRef,
	ReactElement,
	ReactNode,
	RefAttributes,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	UseComboboxState,
	UseComboboxStateChange,
	UseComboboxStateChangeOptions,
	useCombobox,
} from 'downshift';
import { Formatter } from 'afformative';
import { mergeStyles, prepareStyle, prepareStyleFactory, useStyles } from '@creditinfo-ui/styles';
import { either, equals, findIndex, isNil as getIsNil, partition, sortBy } from 'ramda';
import { Message } from '@creditinfo-ui/messages';
import { mergeRefs } from '@creditinfo-ui/utils';
import { Menu, MenuProps } from './Menu';
import { MenuItem } from './MenuItem';
import { identityFormatter } from '../utils';
import { TextInput, TextInputProps, TextInputVariant } from './TextInput';
import { InputWrapper } from './InputWrapper';
import { m } from '../messages';
import { NilMessage } from '../types';
import { useDownshiftFormatter, useOpenDirection } from '../hooks';

type Sorting = 'none' | 'lexicographic';

export type SelectItem = string | number | boolean | object | null;

interface CompatibilitySelectProps {
	/** @deprecated Use `isDisabled` instead. */
	disabled?: boolean;
	/** @deprecated Use `isReadOnly` instead. */
	readOnly?: boolean;
}

export interface SelectProps<TItem extends SelectItem> extends CompatibilitySelectProps {
	addon?: ReactNode;
	/**
	 * Item to select when the input value is cleared via keyboard interaction. When `undefined`,
	 * the currently selected item will remain unchanged.
	 */
	fallbackItem?: TItem | null | undefined;
	formatter?: Formatter<TItem, any, string>;
	id?: string;
	isDisabled?: boolean;
	isInvalid?: boolean;
	isReadOnly?: boolean;
	items: Array<TItem | null>;
	labelId?: string;
	menuProps?: Partial<MenuProps>;
	name?: string;
	/**
	 * Message to use when an item or the `value` prop is `null` or `undefined`.
	 * Pass `false` to delegate nil formatting logic to the `formatter` prop.
	 */
	nilMessage?: NilMessage;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onChange?: (item: TItem | null) => void;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	/** Pass `true` to disable the warning icon when the current `value` is not in `items`. */
	shouldCheckItemAvailability?: boolean;
	/**
	 * Prepends `null` to `items` and sets `fallbackItem` to `null`.
	 *
	 * Useful when rendering components which preconfigure `items` without any option to override
	 * them. Passing `true` alongside a `nilMessage` prop allows consumers to e.g. add an `Any` item,
	 * similar to statics in `@cbs/forms-deprecated`.
	 */
	shouldPrependNullAsFallbackItem?: boolean;
	/** When `items` contain only one item, it will be immediately selected. */
	shouldSelectOnlyItem?: boolean;
	sorting?: Sorting;
	textInputProps?: Partial<TextInputProps>;
	value?: TItem | null;
	variant?: TextInputVariant;
}

const selectStyle = prepareStyle<{ variant: TextInputVariant }>((utils, { variant }) => ({
	display: variant === 'subtle' ? 'inline-block' : 'block',
	position: 'relative',
}));

const comboboxCustomStyle = prepareStyleFactory<{ isOpen: boolean }>((utils, { isOpen }) => ({
	extend: {
		condition: isOpen,
		style: { zIndex: Number(utils.zIndices.menu) + 1 },
	},
}));

const menuCustomStyle = prepareStyleFactory<{ isOpen: boolean }>((utils, { isOpen }) => ({
	extend: {
		condition: !isOpen,
		style: {
			// NOTE: We're relying on `scrollHeight` to calculate the menu height when closed.
			height: 0,
			overflowY: 'hidden',
			visibility: 'hidden',
		},
	},
}));

// NOTE: These hacky values are here so the new select looks like the old select.
// This is important in case they are used side-by-side.
const caretDownIconStyle = prepareStyleFactory<{ variant: TextInputVariant }>(
	(utils, { variant }) => ({
		fontSize: '1.5rem',
		insetInlineEnd: variant === 'subtle' ? utils.spacings.xxs : '1.3rem',
	})
);

const warningIconProps = {
	color: 'warning' as const,
	tooltip: <Message {...m.selectedItemNotAvailable} />,
};

const textInputStyle = prepareStyle<{ isDisabled: boolean }>((utils, { isDisabled }) => ({
	cursor: isDisabled ? undefined : 'pointer',
}));

interface StateReducer<TItem> {
	(state: UseComboboxState<TItem>, actionAndChanges: UseComboboxStateChangeOptions<TItem>): Partial<
		UseComboboxState<TItem>
	>;
}

const UntypedSelect = forwardRef(
	<TItem extends SelectItem>(
		{
			addon,
			disabled,
			fallbackItem: fallbackItemProp,
			formatter: formatterProp = identityFormatter,
			id,
			isDisabled: isDisabledProp,
			// NOTE: Defaulting to `false` here would break `LegacyTextInputContext` integration inside
			// the `TextInput` atom, meaning that invalid selects wouldn't have a red border.
			isInvalid,
			isReadOnly: isReadOnlyProp,
			items: itemsProp,
			labelId,
			menuProps,
			name,
			nilMessage: nilMessageProp,
			onBlur,
			onChange,
			onFocus,
			readOnly,
			shouldCheckItemAvailability = true,
			shouldPrependNullAsFallbackItem = false,
			shouldSelectOnlyItem = false,
			sorting = 'lexicographic',
			textInputProps,
			value,
			variant = 'form',
		}: SelectProps<TItem>,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const { applyStyle } = useStyles();

		const allItems = useMemo(
			() =>
				shouldPrependNullAsFallbackItem && !itemsProp.includes(null)
					? [null, ...itemsProp]
					: itemsProp,
			[itemsProp, shouldPrependNullAsFallbackItem]
		);

		const [filteredItems, setFilteredItems] = useState(allItems);

		const isDisabled = isDisabledProp ?? disabled ?? false;
		const isReadOnly = isReadOnlyProp ?? readOnly ?? false;

		const fallbackItem = shouldPrependNullAsFallbackItem ? null : fallbackItemProp;

		const nilMessage = useMemo(
			() =>
				nilMessageProp === undefined && !allItems.includes(null)
					? ''
					: nilMessageProp ?? m.emptyValue,
			[allItems, nilMessageProp]
		);

		const formatter = useDownshiftFormatter(formatterProp, nilMessage);

		const itemToStringMap = useMemo(
			() =>
				// NOTE: `null` doesn't have to be a member of `allItems`, hence it is prepended.
				[null, ...allItems].reduce<Map<TItem | null, string>>(
					(map, item) => map.set(item, formatter.formatAsPrimitive(item)),
					new Map()
				),
			[allItems, formatter]
		);

		const formatItemAsPrimitive = useCallback(
			(item: TItem | null | undefined) =>
				itemToStringMap.get(item ?? null) ?? formatter.formatAsPrimitive(item),
			[itemToStringMap, formatter]
		);

		const handleStateChange = useCallback(
			(changes: UseComboboxStateChange<TItem | null>) => {
				if (process.env.NODE_ENV === 'development') {
					console.debug(changes);
				}

				const { inputValue } = changes;

				// NOTE: Defined `inputValue` means that it has changed, but it can still be an empty string.
				if (inputValue !== undefined) {
					if (inputValue) {
						setFilteredItems(
							allItems.filter(item =>
								formatItemAsPrimitive(item).toLowerCase().includes(inputValue.toLowerCase())
							)
						);
					} else {
						setFilteredItems(allItems);
					}
				}

				if (changes.selectedItem !== undefined) {
					if (onChange) {
						onChange(changes.selectedItem);
					}

					// NOTE: If an item has been selected, we want to reset filtering as soon as possible
					// to avoid flickering upon reopening the menu.
					setFilteredItems(allItems);
				}

				if (changes.isOpen !== undefined) {
					setFilteredItems(allItems);
				}
			},
			[allItems, onChange, formatItemAsPrimitive]
		);

		const stateReducer: StateReducer<TItem | null> = useCallback(
			(state, actionAndChanges) => {
				const { type, changes } = actionAndChanges;

				if (
					type === useCombobox.stateChangeTypes.InputBlur ||
					type === useCombobox.stateChangeTypes.InputKeyDownEscape
				) {
					if (!state.inputValue && fallbackItem !== undefined) {
						return {
							...changes,
							inputValue: formatItemAsPrimitive(fallbackItem),
							selectedItem: fallbackItem,
						};
					}

					const itemIndex = findIndex(
						item => formatItemAsPrimitive(item).toLowerCase() === state.inputValue.toLowerCase(),
						allItems
					);

					const nextSelectedItem = itemIndex === -1 ? state.selectedItem : allItems[itemIndex];

					return {
						...changes,
						inputValue: formatItemAsPrimitive(nextSelectedItem),
						selectedItem: nextSelectedItem,
					};
				}

				return changes;
			},
			[formatItemAsPrimitive, fallbackItem, allItems]
		);

		const menuItems = useMemo(() => {
			if (sorting === 'lexicographic') {
				const [nilOrFallbackItems, otherFilteredItems] = partition(
					either(getIsNil, equals(fallbackItem)),
					filteredItems
				);

				return [
					...sortBy(formatItemAsPrimitive, nilOrFallbackItems),
					...sortBy(formatItemAsPrimitive, otherFilteredItems),
				];
			}

			return filteredItems;
		}, [fallbackItem, filteredItems, sorting, formatItemAsPrimitive]);

		const {
			closeMenu,
			getComboboxProps,
			getInputProps,
			getItemProps,
			getMenuProps,
			highlightedIndex,
			isOpen,
			selectedItem,
			selectItem,
			setInputValue,
			toggleMenu,
		} = useCombobox<TItem | null>({
			initialInputValue: formatItemAsPrimitive(value),
			inputId: id,
			items: menuItems,
			itemToString: formatItemAsPrimitive,
			labelId,
			onStateChange: handleStateChange,
			selectedItem: value,
			stateReducer,
		});

		// NOTE: This `useEffect` is necessary so we can react to `formatter` being changed, e.g. when
		// translations are loaded. Unfortunately, Downshift ignores changes to `itemToString`.
		useEffect(() => {
			setInputValue(formatter.formatAsPrimitive(selectedItem));
		}, [setInputValue, formatter, selectedItem]);

		useEffect(() => {
			if (isDisabled) {
				closeMenu();
			}
		}, [isDisabled, closeMenu]);

		// NOTE: This `useEffect` is necessary along with `visibility: 'hidden'` for `getMenuHeight`
		// to return the correct height even with the menu closed. This enables `useOpenDirection` to
		// calculate the direction correctly before the first interaction with the `Select`.
		useEffect(() => {
			if (!isOpen) {
				setFilteredItems(allItems);
			}
		}, [allItems, isOpen]);

		const handleTextInputClick = useCallback(
			event => {
				toggleMenu();

				if (!isReadOnly) {
					event.target.select();
				}
			},
			[toggleMenu, isReadOnly]
		);

		const isSelectedItemAvailable = useMemo(
			() =>
				!shouldCheckItemAvailability ||
				getIsNil(selectedItem) ||
				allItems.some(item => formatItemAsPrimitive(item) === formatItemAsPrimitive(selectedItem)),
			[allItems, selectedItem, formatItemAsPrimitive, shouldCheckItemAvailability]
		);

		useEffect(() => {
			if (!shouldSelectOnlyItem || allItems.length !== 1) {
				return;
			}

			const nextSelectedItem = allItems[0];

			const shouldSelectItem =
				!equals(nextSelectedItem, selectedItem) &&
				(getIsNil(selectedItem) || equals(selectedItem, fallbackItem));

			if (!shouldSelectItem) {
				return;
			}

			if (process.env.NODE_ENV === 'development') {
				console.debug({ allItems, fallbackItem, nextSelectedItem, selectedItem });
			}

			selectItem(nextSelectedItem);
		}, [
			allItems,
			fallbackItem,
			// HACK: `formatter` is here on purpose to counteract the `useEffect` responsible for
			// updating the input value when `formatter` changes reference.
			formatter,
			selectItem,
			selectedItem,
			shouldSelectOnlyItem,
		]);

		const textInputRef = useRef<HTMLInputElement>(null);
		const menuRef = useRef<HTMLUListElement>(null);

		const getMenuHeight = useCallback(
			() => menuRef.current?.clientHeight || menuRef.current?.scrollHeight || 0,
			[]
		);

		const { openDirection } = useOpenDirection({
			getRequiredHeight: getMenuHeight,
			shouldUpdate: isOpen,
			triggerRef: textInputRef,
		});

		return (
			<div className={applyStyle(selectStyle, { variant })}>
				<InputWrapper
					addon={addon}
					addonPlacement="start"
					customStyle={comboboxCustomStyle({ isOpen })}
					icon={isSelectedItemAvailable ? 'caretDown' : 'warning'}
					iconProps={
						isSelectedItemAvailable
							? { customStyle: caretDownIconStyle({ variant }) }
							: warningIconProps
					}
					isDisabled={isDisabled}
					{...getComboboxProps()}
				>
					<TextInput
						autoComplete="off"
						isDisabled={isDisabled}
						isFocused={isOpen}
						isInvalid={isInvalid}
						isReadOnly={isReadOnly}
						// NOTE: Passing `name` down would enable autofill in fields such as `country`. This is
						// undesirable in selects due to overlapping visuals. However, we still need to access
						// the name for debugging purposes and in E2E tests.
						testName={name}
						variant={variant}
						{...getInputProps({
							// NOTE: Prevents Downshift event handlers from executing, but `isDisabled` still
							// has a higher priority in `TextInput`, so `isReadOnly` won't disable the input.
							disabled: isDisabled || isReadOnly,
							onBlur,
							onClick: handleTextInputClick,
							onFocus,
							ref: ref ? mergeRefs([textInputRef, ref]) : textInputRef,
							...textInputProps,
						})}
						customStyle={mergeStyles([textInputStyle, textInputProps?.customStyle])}
					/>
				</InputWrapper>
				<Menu
					customStyle={menuCustomStyle({ isOpen })}
					direction={openDirection}
					isEmbedded
					hasShadow
					hasPositionAbsolute
					{...getMenuProps({
						...menuProps,
						ref: menuRef,
					})}
				>
					{menuItems.map((item, itemIndex) => (
						<MenuItem
							isHighlighted={highlightedIndex === itemIndex}
							key={`${formatItemAsPrimitive(item)}_${itemIndex}`}
							size={variant === 'subtle' ? 'sm' : 'lg'}
							{...getItemProps({
								disabled: isDisabled || isReadOnly,
								index: itemIndex,
								item,
							})}
						>
							{formatter.format(item)}
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
);

export const Select = UntypedSelect as <TItem extends SelectItem>(
	props: SelectProps<TItem> & RefAttributes<HTMLInputElement>
) => ReactElement;
