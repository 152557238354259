import { defineMessages } from 'react-intl';

export default defineMessages({
	redirecting: {
		id: 'authentication.redirecting',
		defaultMessage: 'Redirecting to login page...',
		description: 'Label to show when redirecting to login page.',
	},
	resetPasswordFail: {
		id: 'authentication.resetPasswordFail',
		defaultMessage: 'Password reset failed.',
		description: 'Password reset fail notification.',
	},
	resetTokenInvalid: {
		id: 'authentication.resetTokenInvalid',
		defaultMessage: 'Reset token is invalid.',
		description: 'Password reset token fail notification.',
	},
	resetPasswordSuccess: {
		id: 'authentication.resetPasswordSuccess',
		defaultMessage: 'Password reset was successful.',
		description: 'Password reset success notification.',
	},
	changePasswordFail: {
		id: 'authentication.changePasswordFail',
		defaultMessage: 'Password change failed - {error}',
		description: 'Password change fail notification.',
	},
	changePasswordSuccess: {
		id: 'authentication.changePasswordSuccess',
		defaultMessage: 'Password change was successful.',
		description: 'Password change success notification.',
	},
	signInFail: {
		id: 'authentication.signInFail',
		defaultMessage: 'Signing in failed.',
		description: 'SignIn fail notification.',
	},
});
