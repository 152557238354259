import { useMemo } from 'react';

export const simplifyUseResolve =
	<TUseResolve extends () => (...args: any[]) => any>(useResolve: TUseResolve) =>
	(...args: Parameters<ReturnType<TUseResolve>>): ReturnType<ReturnType<TUseResolve>> => {
		const resolve = useResolve();

		// eslint-disable-next-line react-hooks/exhaustive-deps
		return useMemo(() => resolve(...args), [...args, resolve]);
	};
