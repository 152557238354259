import { last } from 'ramda';
import { makeActionTypes, makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';
import { composeMiddleware, downloadBase64File, makeMiddleware, typeEq } from '@myci/utils';
import { isFetching, request } from '@ci/api';
import { run } from '@ci/control-flow';
import { addToast } from '@ci/toasts';
import { createDataManager } from '@ci/entities';
import { Amount } from '@myci/formatters';

import m from '../messages';

export interface Receipt {
	amount: Amount;
	channel: string;
	channelLocalized: string;
	date: string;
	description: string;
	explanationLocalized: string;
	id: number;
	invoiceNumber?: string;
	productKey: string;
	productKeyLocalized: string;
	receiptNumber: string;
	type: string;
}

export const transactionManager = createDataManager<Receipt>('transaction');

const ActionTypes = makeActionTypes('@transactions', [
	'FETCH_WALLET_TRANSACTIONS',
	'FETCH_RECEIPT_FILE',
]);

export const fetchWalletTransactions = makeConstantActionCreator(
	ActionTypes.FETCH_WALLET_TRANSACTIONS
);
export const fetchReceiptFile = makeSimpleActionCreator(ActionTypes.FETCH_RECEIPT_FILE);

export const isFetchingWalletTransactions = isFetching(ActionTypes.FETCH_WALLET_TRANSACTIONS);

export const getReceipts = transactionManager.selectBy(transaction =>
	Boolean(transaction.receiptNumber)
);
export const getLastReceiptDate = (state: any) => last(getReceipts(state) ?? [])?.date;

const fetchWalletTransactionsMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_WALLET_TRANSACTIONS),
	({ dispatch }) =>
		action => {
			dispatch(
				run(
					() =>
						request(
							{
								url: 'wallet/transactions',
							},
							{
								origin: action,
							}
						),
					({ payload }, { isError }) => {
						if (isError) {
							return dispatch(
								addToast({
									type: 'warning',
									content: m.transactionFetchFailed,
								})
							);
						}

						dispatch(transactionManager.clearAndInsert(payload.result.transactions));
					}
				)
			);
		}
);

const fetchReceiptFileMiddleware = makeMiddleware(
	typeEq(ActionTypes.FETCH_RECEIPT_FILE),
	({ dispatch }) =>
		action => {
			const { receiptNumber, receiptPrefix } = action.payload;
			dispatch(
				run(
					() =>
						request(
							{
								url: `/wallet/receipt/${receiptNumber}`,
								method: 'GET',
							},
							{
								origin: action,
							}
						),
					(action, { isError }) => {
						if (isError) {
							dispatch(
								addToast({
									content: m.downloadReceiptError,
									type: 'danger',
								})
							);
						} else {
							const pdfFile = action?.payload?.result?.receipt?.pdfFile;
							if (pdfFile) {
								downloadBase64File(pdfFile, `${receiptPrefix}_${receiptNumber}.pdf`);
							}
						}
					}
				)
			);
		}
);

export const walletTransactionsMiddleware = composeMiddleware(
	fetchWalletTransactionsMiddleware,
	fetchReceiptFileMiddleware
);
