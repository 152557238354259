import { makeFormatter } from 'afformative';
import { isNotNilNorEmpty } from '@ci/utils';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import { makeMomentFormatter } from '@creditinfo-ui/formatters';

export const TimestampFormatter = makeMomentFormatter('llll', 'TimestampFormatter');

export const YearMonthFormatter = makeMomentFormatter('MM-YYYY', 'YearMonthFormatter');

const toTwoDigits = integer => `0${integer}`.slice(-2);

export const HourFormatter = makeFormatter(
	value => `${toTwoDigits(value)}-${toTwoDigits(value + 1)}`,
	{
		displayName: 'HourFormatter',
	}
);

export const DurationFormatter = makeFormatter(
	value => (value ? humanizeDuration(moment.duration(value)) : ''),
	{
		displayName: 'DurationFormatter',
	}
);

const removeYear = date =>
	date.format('L').replace(new RegExp(`[^.]?${date.format('YYYY')}.?`), '');

export const EndOfQuarterFormatter = makeFormatter(
	quarterIndex =>
		isNotNilNorEmpty(quarterIndex)
			? removeYear(moment().quarter(quarterIndex).endOf('quarter'))
			: '',
	{
		displayName: 'EndOfQuarterFormatter',
	}
);

export const MonthFormatter = makeFormatter(
	(monthIndex, suggestions) =>
		monthIndex
			? moment[suggestions.includes('abbreviated') ? 'monthsShort' : 'months']()[monthIndex - 1]
			: '',
	{
		displayName: 'MonthFormatter',
	}
);

export const timeDifferenceFormatter = makeFormatter(([startTime, endTime]) => {
	const differenceInSeconds = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();

	return moment().startOf('day').seconds(Math.round(differenceInSeconds)).format('HH:mm:ss');
});

export const humanizedTimeDifferenceFormatter = makeFormatter(([startTime, endTime]) =>
	humanizeDuration(moment.duration(moment(endTime).diff(moment(startTime))))
);
