import { compose, filter, find, includes, pluck, prop } from 'ramda';
import { IdentifierDetail } from './duck';

const getIsAllowed = (nationality: number) =>
	(compose as any)(includes(nationality), pluck('id'), prop('allowedCountries'));

export const getNationalitySpecificIdentifierDetail = (
	nationality: number,
	identifierDetails: IdentifierDetail[] = []
) => find(getIsAllowed(Number(nationality)), identifierDetails);

export const getNationalitySpecificIdentifierDetails = (
	nationality: number,
	identifierDetails: IdentifierDetail[] = []
) => filter(getIsAllowed(nationality), identifierDetails);
