import { Icon, IconType } from '@creditinfo-ui/atoms';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { useCallback, useMemo } from 'react';

import { ToastBody } from './ToastBody';
import { ToastDefinition, ToastId, ToastType } from './types';
import { useDelayedHoverEffect } from './useDelayedHoverEffect';

const getIconType = (type: ToastType): IconType => {
	switch (type) {
		case 'danger':
			return 'failure';
		case 'warning':
			return 'warning';
		case 'announcement':
			return 'info';
		default:
			return 'success';
	}
};

// TODO: This function should be removed in the future.
const resolveIncompatibleType = (type?: string): ToastType => {
	// NOTE: `type` is missing in some old JS files, we're using success as the fallback,
	// because it's the most common.
	if (!type) return 'success';
	// NOTE: `error` is used in some old JS files, `danger` is preferred.
	if (type === 'error') return 'danger';

	return type as ToastType;
};

interface ToastStyleProps {
	type: ToastType;
}

const TOAST_PADDING = '3rem';

const TOAST_CLOSE_TIMEOUT = 5000;

const toastStyle = prepareStyle<ToastStyleProps>((utils, { type }) => ({
	backgroundColor: '#fff',
	borderInlineStartColor: utils.colors[type],
	borderInlineStartStyle: 'solid',
	borderInlineStartWidth: utils.borders.widths.xxl,
	borderRadius: utils.borders.radii.basic,
	boxShadow: utils.boxShadows.basic,
	display: 'flex',
	flexDirection: 'row',
	marginInlineEnd: 0,
	marginInlineStart: 'auto',
	marginTop: utils.spacings.sm,
	maxWidth: utils.maxWidths.xs,
	overflow: 'hidden',
	paddingBottom: utils.spacings.md,
	paddingInlineEnd: TOAST_PADDING,
	paddingInlineStart: TOAST_PADDING,
	paddingTop: utils.spacings.lg,
	position: 'relative',
	width: '100%',
}));

const toastIconStyle = prepareStyle(utils => ({
	insetInlineStart: utils.spacings.sm,
	// HACK: Margin is set so the icon is inline with the toast content.
	marginTop: '3px',
	position: 'absolute',
}));

const toastCloseStyle = prepareStyle(utils => ({
	cursor: 'pointer',
	insetInlineEnd: utils.multiply(0.5, TOAST_PADDING),
	position: 'absolute',
	top: utils.multiply(0.5, TOAST_PADDING),
}));

const toastCloseIconStyle = prepareStyle(() => ({
	paddingInlineEnd: 0,
}));

export interface ToastProps extends ToastDefinition {
	onClose: (id: ToastId) => void;
	onExpand?: (expansionId: string) => void;
}

export const Toast = ({
	content,
	expansionId,
	hasRichContent,
	id,
	onClose,
	onExpand,
	shouldAutomaticallyClose = true,
	type: possiblyIncompatibleType,
	values,
}: ToastProps) => {
	const { applyStyle } = useStyles();

	const type = resolveIncompatibleType(possiblyIncompatibleType);

	const handleClose = useCallback(() => {
		onClose(id);
	}, [id, onClose]);

	const handleExpand = useMemo(
		() => (onExpand && expansionId ? () => onExpand(expansionId) : undefined),
		[expansionId, onExpand]
	);

	const { onMouseLeave, onMouseOver } = useDelayedHoverEffect(
		handleClose,
		TOAST_CLOSE_TIMEOUT,
		shouldAutomaticallyClose
	);

	return (
		<div
			className={applyStyle(toastStyle, { type })}
			onMouseLeave={onMouseLeave}
			onMouseOver={onMouseOver}
		>
			<div className={applyStyle(toastIconStyle)}>
				<Icon type={getIconType(type)} size="lg" color={type} />
			</div>

			<ToastBody
				content={content}
				hasRichContent={hasRichContent ?? type === 'announcement'}
				onExpand={handleExpand}
				values={values}
			/>
			<div className={applyStyle(toastCloseStyle)} onClick={handleClose}>
				<Icon type="close" size="md" color="gray400" className={applyStyle(toastCloseIconStyle)} />
			</div>
		</div>
	);
};
