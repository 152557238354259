import { noop } from 'ramda-extension';
import { createContext } from 'react';

export interface CollapsibleControls {
	expand: () => void;
	isExpanded: boolean;
}

export const CollapsibleControlsContext = createContext<CollapsibleControls>({
	expand: noop,
	isExpanded: false,
});
