import { ReactNode } from 'react';
import { prepareStyle, useStyles } from '@ci/styles';

type CollapsibleTitleSize = 'large' | 'small';

const collapsibleTitleStyle = prepareStyle<{ size: CollapsibleTitleSize }>((utils, { size }) => ({
	marginBottom: utils.spacings.xs,
	whiteSpace: 'nowrap',

	selectors: {
		[utils.breakpoints.lg]: {
			marginBottom: 0,
		},
	},

	extend: [
		{
			condition: size === 'small',
			style: {
				fontSize: utils.fontSizes.base,
				fontWeight: utils.fontWeights.semiBold,
			},
		},
		{
			condition: size === 'large',
			style: {
				fontSize: utils.fontSizes.collapsibleTitle,
				fontWeight: utils.fontWeights.bold,
			},
		},
	],
}));

export interface CollapsibleTitleProps {
	size?: CollapsibleTitleSize;
	title: ReactNode;
}

export const CollapsibleTitle = ({ size = 'large', title }: CollapsibleTitleProps) => {
	const { applyStyle } = useStyles();

	return (
		<span role="heading" className={applyStyle(collapsibleTitleStyle, { size })}>
			{title}
		</span>
	);
};
