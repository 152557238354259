import { Middleware, Store } from 'redux';
import { makeAuthenticationMiddleware as untypedMakeAuthenticationMiddleware } from './middleware';

export { flushRequests } from './middleware';
export { sha256, generateRandomString } from './crypto';
export { getIsTokenExpired } from './utils';
export { useResolveAccessToken } from './hooks';

export const makeAuthenticationMiddleware: (params: Record<string, any>) => Middleware & {
	initialize: (store: Store) => void;
	reset: () => void;
} = untypedMakeAuthenticationMiddleware as any;
