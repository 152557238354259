import React, { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { Select, SelectItem, SelectProps } from '@ci/atoms';
import { isNilOrEmpty } from 'ramda-extension';
import { head, isNil } from 'ramda';
import { isSelectStatic } from '../../constants';
import { SelectStatic } from '../../types';

const defaultStatics = [];

export interface SelectControlAutocompleteProps<TItem extends SelectItem = SelectItem>
	extends Omit<SelectProps<TItem>, 'onBlur'> {
	disableLexicographicOrder?: boolean;
	onBlur?: () => void;
	statics?: SelectStatic[];
}

// NOTE: This component serves purely as a compatibility layer between the old usage of `SelectField`
// and the `Select` atom.
export const SelectControlAutocomplete = forwardRef(
	(
		{
			disableLexicographicOrder = false,
			fallbackItem,
			items,
			onBlur,
			onChange,
			statics,
			value,
			...otherProps
		}: SelectControlAutocompleteProps,
		ref: ForwardedRef<HTMLInputElement>
	) => {
		const selectStatic = head(statics ?? defaultStatics);

		const nextItems = useMemo(
			() => (!selectStatic || selectStatic.hidden ? items : [null, ...items]),
			[selectStatic, items]
		);

		const handleChange = useCallback(
			nextValue => {
				if (onChange) {
					onChange(isNil(nextValue) && selectStatic ? selectStatic : nextValue);
				}
			},
			[onChange, selectStatic]
		);

		const handleBlur = useCallback(() => {
			if (onBlur) {
				onBlur();
			}
		}, [onBlur]);

		return (
			<Select
				fallbackItem={selectStatic ? null : fallbackItem}
				items={nextItems}
				nilMessage={selectStatic?.message}
				onBlur={handleBlur}
				onChange={handleChange}
				ref={ref}
				sorting={disableLexicographicOrder ? 'none' : 'lexicographic'}
				value={isNilOrEmpty(value) || isSelectStatic(value) ? null : value}
				{...otherProps}
			/>
		);
	}
);

SelectControlAutocomplete.displayName = 'SelectControlAutocomplete';
