import { TransitionSpeed, useStyles } from '@creditinfo-ui/styles';
import { ReactNode } from 'react';
import { Transition as TransitionGroupTransition, TransitionStatus } from 'react-transition-group';

// HACK: Force browser to repaint and perform animation correctly.
const forceNodeRepaint = (node: any) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-expressions
	node?.scrollTop;
};

export interface TransitionProps {
	appear?: boolean;
	children: (status: TransitionStatus) => ReactNode;
	enter?: boolean;
	exit?: boolean;
	in?: boolean;
	mountOnEnter?: boolean;
	onExited?: () => void;
	speed: TransitionSpeed;
	unmountOnExit?: boolean;
}

export const Transition = ({ speed, appear = true, ...otherProps }: TransitionProps) => {
	const { utils } = useStyles();
	const [timeout] = utils.getValueAndUnit(utils.transitions.speeds[speed]);

	return (
		<TransitionGroupTransition
			timeout={timeout}
			appear={appear}
			onEntering={forceNodeRepaint}
			onEnter={forceNodeRepaint}
			onExiting={forceNodeRepaint}
			{...otherProps}
		/>
	);
};
