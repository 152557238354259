import React, { MouseEvent } from 'react';
import { Button, ButtonProps } from '@ci/atoms';
import { useTimer } from '@myci/react-utils';

interface CountdownButtonProps extends ButtonProps {
	countdown?: number;
}

export const CountdownButton = ({
	countdown = 60,
	children,
	onClick,
	isDisabled,
	...otherProps
}: CountdownButtonProps) => {
	const { elapsedTime, startTimer } = useTimer(countdown);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (onClick) {
			onClick(e);
		}
		startTimer();
	};

	return (
		<Button {...otherProps} onClick={handleClick} isDisabled={elapsedTime !== null || isDisabled}>
			{elapsedTime !== null ? (
				<span>
					{children} {`(${countdown - elapsedTime})`}
				</span>
			) : (
				children
			)}
		</Button>
	);
};
