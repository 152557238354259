import { addToast } from '@ci/toasts';
import { sleep } from '@ci/utils';
import { isInBrowser as getIsInBrowser } from '@myci/utils';
import { navigate } from '@myci/navigation';
import { createThunk, request, selectIsThunkPending } from '@ci/api';
import { requestBiometricAuthenticationUpdate } from '@myci/native-biometrics/setup';
import { signIn } from '@myci/authentication';

import m from '../messages';

import { getUsername, setAccountState } from './account';

interface ChangePasswordPayload {
	changedPassword: string;
	confirmPassword: string;
	currentPassword: string;
	form?: string;
}
export const changePassword = createThunk(
	{
		originType: '@changePassword/changePassword',
		errorMessage: m.changePasswordFail,
	},
	async ({ dispatch, getState }, payload: ChangePasswordPayload) => {
		const successAction = await dispatch(
			request({
				url: '/password/change',
				method: 'POST',
				body: payload,
			})
		);

		const isInBrowser = getIsInBrowser();

		if (!isInBrowser) {
			dispatch(
				requestBiometricAuthenticationUpdate({
					password: successAction.payload.changedPassword,
				})
			);
		}

		const username = getUsername(getState());
		dispatch(addToast({ content: m.changePasswordSuccess, type: 'success' }));

		if (username) {
			await dispatch(
				signIn({
					password: payload.changedPassword,
					username,
				})
			);
		}

		if (isInBrowser) {
			// NOTE: delay redirect so that the user can see the success message
			await sleep(3000);
		}

		dispatch(setAccountState({ needsToChangePassword: false }));

		if (isInBrowser) {
			window.location.replace('../settings');
		} else {
			navigate('../settings');
		}
	}
);

export const selectIsChangingPassword = selectIsThunkPending(changePassword);
