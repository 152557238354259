import { useCallback, useState } from 'react';

import { useInterval } from './useInterval';

export const useTimer = duration => {
	const [elapsedTime, setElapsedTime] = useState<number | null>(null);

	const [start, reset] = useInterval(() => {
		if (elapsedTime !== null && elapsedTime < duration - 1) {
			setElapsedTime(elapsedTime + 1);
		} else {
			reset();
			setElapsedTime(null);
		}
	}, 1000);

	// Note: we need to ensure that this happens after subnmitting the form
	const startTimer = useCallback(() => {
		setTimeout(() => {
			setElapsedTime(0);
			start();
		});
	}, [setElapsedTime, start]);

	return { startTimer, elapsedTime };
};
