import { head } from 'ramda';
import { isString } from 'ramda-extension';

/**
 * MyCI API does not send error messages in standardized way. Should be discussed with BE devs, then data could be removed.
 * @param response
 * @returns {*}
 */
export const getErrorMessageFromResponse = response => {
	const { error, errors, error_description: errorDescription, data } = response;

	const errorMessage =
		(error && error.messageLocalized) ||
		errorDescription ||
		(errors ? head(errors)?.message : undefined) ||
		(isString(data) ? data : undefined);

	return errorMessage;
};
