import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { entitySelectMiddleware, formMiddleware } from '@myci/ui-components-redux';
import { makeMiddlewareEnhancer, makeReducersEnhancer, thunkMiddleware } from 'redux-syringe';
import { middleware as controlFlowMiddleware } from '@ci/control-flow';
import {
	reducer as account,
	accountMiddleware,
	changeEmailReducer,
	changePhoneReducer,
} from '@myci/user-settings';
import { reducer as dataApiReducer } from '@myci/domain-data-api';
import { apiReducer, promiseLikeRequestMiddleware } from '@ci/api';
import { apiMiddleware } from '@myci/api';
import { reducer as entities, middleware as entityMiddleware } from '@ci/entities';
import { submittedValuesReducer } from '@ci/redux-form';
import { reducer as lookups } from '@ci/lookups';
import { reducer as form } from 'redux-form';
import { reducer as intl, middleware as intlMiddleware } from '@myci/intl';
import { reducer as planManagement } from '@myci/domain-plan-management';
import {
	reducer as authentication,
	middleware as authenticationMiddleware,
} from '@myci/authentication';
import { reducer as attachments } from '@myci/attachments';
import { initializeSessionTimeout } from '@myci/session-timeout';

import packageJson from '../../package.json';

const configureStore = ({ preloadedState }) => {
	const middlewareEnhancer = makeMiddlewareEnhancer();
	const { injectedMiddleware } = middlewareEnhancer;
	const reducersEnhancer = makeReducersEnhancer();

	const composeEnhancers = composeWithDevTools({
		name: `${packageJson.name}@${packageJson.version}`,
		// NOTE: This option will allow us to see e.g. error payloads
		serialize: true,
		// NOTE: if not set the extension breaks after injecting reducers
		latency: 0,
		maxAge: 1000,
	});

	const store = createStore(
		preloadedState,
		composeEnhancers(
			reducersEnhancer,
			middlewareEnhancer,
			// NOTE: `authenticationMiddleware` must be before `apiMiddleware` so it can stop AJAX requests.
			// The rest should be mounted anywhere after them.
			applyMiddleware(
				thunkMiddleware,
				promiseLikeRequestMiddleware,
				authenticationMiddleware,
				apiMiddleware,
				controlFlowMiddleware,
				intlMiddleware,
				accountMiddleware,
				entityMiddleware,
				entitySelectMiddleware,
				formMiddleware,
				injectedMiddleware
			)
		)
	);

	store.injectReducers({
		attachments,
		entities,
		intl,
		authentication,
		api: apiReducer,
		changeEmail: changeEmailReducer,
		changePhone: changePhoneReducer,
		dataApi: dataApiReducer,
		account,
		planManagement,
		lookups,
		form,
		'@ci/redux-form': submittedValuesReducer,
	});

	// NOTE: Authentication middleware initialization synchronously calls `navigate` from `gatsby`,
	// which depends on some other synchronous initialization (done internally by Gatsby).
	setTimeout(() => {
		authenticationMiddleware.initialize(store);
	});

	initializeSessionTimeout(store);

	return store;
};

export default configureStore;
