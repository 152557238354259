import { actionTypes as ReduxFormActionTypes, FormAction } from 'redux-form';
import { path, curry, assocPath } from 'ramda';
import { createAction, createReducer } from '@reduxjs/toolkit';

export const saveSubmittedValues = createAction<{ formId: string; values: Record<string, any> }>(
	'@ci/redux-form/saveSubmittedValues'
);

export const getSubmittedValues = curry((formId: string, state: any) => {
	const { values, isDestroyed } =
		path<Record<string, any>>(['@ci/redux-form', 'submittedValues', formId], state) ?? {};

	return isDestroyed ? undefined : values;
});

export const selectPersistentSubmittedValues = (formId: string) => (state: any) =>
	path<Record<string, any>>(['@ci/redux-form', 'submittedValues', formId, 'values'], state);

const initialState = { submittedValues: {} };

export const submittedValuesReducer = createReducer(initialState, builder => {
	builder
		.addCase(ReduxFormActionTypes.DESTROY, (state, { meta: { form } }: FormAction) =>
			state.submittedValues[form]
				? assocPath(['submittedValues', form, 'isDestroyed'], true, state)
				: state
		)
		.addCase(saveSubmittedValues, (state, { payload: { values, formId } }) => ({
			...state,
			submittedValues: {
				...state.submittedValues,
				[formId]: {
					values,
					isDestroyed: false,
				},
			},
		}));
});
