import { isArray } from 'ramda-extension';
import { useContext, useEffect } from 'react';

import { TranslationFetchingContext } from './contexts';
import { MessageId } from './types';

export const useTranslationsFetchEffect = (
	messageIds: Array<MessageId | null | undefined> | MessageId | null | undefined
) => {
	const { getIsTranslationFetched, registerMessage } = useContext(TranslationFetchingContext);

	useEffect(() => {
		const messageIdsArray = isArray(messageIds) ? messageIds : [messageIds];

		messageIdsArray.forEach(messageId => {
			if (!messageId || getIsTranslationFetched(messageId)) {
				return;
			}

			registerMessage(messageId);
		});
	}, [getIsTranslationFetched, messageIds, registerMessage]);
};

/**
 * @deprecated Use `useTranslationsFetchEffect` instead.
 */
export const useTranslationPrefetching = useTranslationsFetchEffect;
