import React from 'react';
import { Button, Dropdown, DropdownProps } from '@ci/atoms';
import { Style, useStyles } from '@ci/styles';

export interface SplitButtonProps extends Omit<DropdownProps<any>, 'nilMessage' | 'customStyle'> {
	customStyle?: Style;
	label: string;
}

export const SplitButton = ({
	items,
	onClick,
	isBusy,
	customStyle,
	label,
	...otherProps
}: SplitButtonProps) => {
	const { applyStyle } = useStyles();

	return (
		<div className={applyStyle([customStyle])}>
			{items?.length > 1 ? (
				<Dropdown
					items={items}
					isSplitButton
					nilMessage={label}
					isBusy={isBusy}
					onClick={onClick}
					variant="primary"
					{...otherProps}
				/>
			) : (
				<Button onClick={onClick} isBusy={isBusy}>
					{label}
				</Button>
			)}
		</div>
	);
};
