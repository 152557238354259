import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { head, isEmpty, last, mapObjIndexed, reduce, reduced } from 'ramda';
import { noop } from 'ramda-extension';
import { getElementTotalWidth, loadIconAsset } from '@creditinfo-ui/atoms';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import { TabItem, TabKey } from './types';

export const useFindTabIndex = (tabs: TabItem[]) =>
	useCallback((key: TabKey) => tabs.findIndex(tab => tab.key === key), [tabs]);

export const useFindNextTabKey = (tabs: TabItem[]) => {
	const findTabIndex = useFindTabIndex(tabs);

	return useCallback(
		(key: TabKey) => {
			const nextIndex = findTabIndex(key) + 1;
			const nextTab = tabs[nextIndex] ?? last(tabs);

			return nextTab.key;
		},
		[tabs, findTabIndex]
	);
};

export const useFindPreviousTabKey = (tabs: TabItem[]) => {
	const findTabIndex = useFindTabIndex(tabs);

	return useCallback(
		(key: TabKey) => {
			const nextIndex = findTabIndex(key) - 1;
			const nextTab = tabs[nextIndex] ?? head(tabs);

			return nextTab.key;
		},
		[tabs, findTabIndex]
	);
};

export const useTabController = (tabs: TabItem[], initialKey?: TabKey) => {
	const [activeKey, setActiveKey] = useState(initialKey ?? tabs[0]?.key);
	const findTabIndex = useFindTabIndex(tabs);
	const activeIndex = useMemo(() => findTabIndex(activeKey), [findTabIndex, activeKey]);

	const findNextTabKey = useFindNextTabKey(tabs);
	const findPreviousTabKey = useFindPreviousTabKey(tabs);

	const next = useCallback(() => setActiveKey(findNextTabKey), [findNextTabKey]);
	const previous = useCallback(() => setActiveKey(findPreviousTabKey), [findPreviousTabKey]);

	const props = useMemo(
		() => ({
			activeKey,
			setActiveKey,
			tabs,
		}),
		[activeKey, tabs]
	);

	const isFirst = activeIndex === 0;
	const isLast = activeIndex === tabs.length - 1;

	return {
		activeIndex,
		activeKey,
		isFirst,
		isLast,
		next,
		previous,
		props,
		setActiveKey,
	};
};

export interface TabControls {
	/** @deprecated Use `useCurrentTabControls().isFirst` instead. */
	isFirst: boolean;
	/** @deprecated Use `useCurrentTabControls().isLast` instead. */
	isLast: boolean;
	/** @deprecated Use `useCurrentTabControls().isActive` instead. */
	isVisible: boolean;
	next: () => void;
	previous: () => void;
	setActiveKey: (key: TabKey) => void;
}

export const TabControlsContext = createContext<TabControls>({
	isFirst: false,
	isLast: false,
	isVisible: false,
	next: noop,
	previous: noop,
	setActiveKey: noop,
});

export const useTabControls = () => useContext(TabControlsContext);

export interface CurrentTabControls {
	activate: () => void;
	isActive: boolean;
	isFirst: boolean;
	isLast: boolean;
	key: TabKey | null;
}

export const CurrentTabControlsContext = createContext<CurrentTabControls>({
	activate: noop,
	isActive: false,
	isFirst: false,
	isLast: false,
	key: null,
});

export const useCurrentTabControls = () => useContext(CurrentTabControlsContext);

export const useTabDropdown = (tabs: TabItem[], activeKey: TabKey) => {
	const tabBarRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLButtonElement>(null);
	const tabElementsRef = useRef<Record<string, HTMLButtonElement>>({});

	const [tabWidths, setTabWidths] = useState<Record<string, number>>({});
	const [dropdownWidth, setDropdownWidth] = useState(0);
	const [shownTabsCount, setShownTabsCount] = useState(tabs.length);

	const areWidthStatesInitialized = dropdownWidth !== 0 && !isEmpty(tabWidths);

	const makeTabRefCallback = key => element => {
		tabElementsRef.current[key] = element;
	};

	useEffect(() => {
		loadIconAsset('caretDown').then(() => {
			setTimeout(() => {
				if (dropdownRef.current) {
					setDropdownWidth(getElementTotalWidth(dropdownRef.current));
				}
			});
		});
	}, []);

	useEffect(() => {
		if (!isEmpty(tabElementsRef.current) && isEmpty(tabWidths)) {
			setTabWidths(mapObjIndexed(getElementTotalWidth, tabElementsRef.current));
		}
	}, [tabWidths]);

	const handleResize = useCallback(() => {
		if (areWidthStatesInitialized && tabBarRef.current) {
			const tabBarWidth = getElementTotalWidth(tabBarRef.current);

			const { shownTabsCount: nextShownTabsCount } = reduce(
				({ shownTabsCount: accumulatedShownTabsCount, tabsWidth }, tab) => {
					const nextTabsWidth = tabsWidth + tabWidths[tab.key];

					if (nextTabsWidth <= tabBarWidth - (tab === last(tabs) ? 0 : dropdownWidth)) {
						return {
							shownTabsCount: accumulatedShownTabsCount + 1,
							tabsWidth: nextTabsWidth,
						};
					}

					return reduced({ shownTabsCount: accumulatedShownTabsCount, tabsWidth });
				},
				{ shownTabsCount: 0, tabsWidth: 0 },
				tabs
			);

			setShownTabsCount(nextShownTabsCount);
		}
	}, [dropdownWidth, tabWidths, areWidthStatesInitialized, tabs]);

	useResizeDetector({
		handleHeight: false,
		onResize: handleResize,
		skipOnMount: true,
		targetRef: tabBarRef,
	});

	useLayoutEffect(() => {
		if (areWidthStatesInitialized) {
			handleResize();
		}
	}, [areWidthStatesInitialized, handleResize]);

	const dropdownItems = useMemo(
		() => tabs.filter((tab, index) => index >= shownTabsCount),
		[tabs, shownTabsCount]
	);

	const isDropdownActive = useMemo(
		() => dropdownItems.some(tab => tab.key === activeKey),
		[activeKey, dropdownItems]
	);

	const shouldRenderDropdown = shownTabsCount < tabs.length || dropdownWidth === 0;

	// NOTE: `getElementTotalWidth` always returns 0 in tests, meaning the dropdown calculation logic
	// cannot be tested. As a fallback, we simply render all tabs in both the dropdown and the tablist.
	if (process.env.NODE_ENV === 'test') {
		return {
			areWidthStatesInitialized: true,
			dropdownItems: tabs,
			dropdownRef,
			isDropdownActive: true,
			makeTabRefCallback,
			shouldRenderDropdown: true,
			shownTabsCount: tabs.length,
			tabBarRef,
		};
	}

	return {
		areWidthStatesInitialized,
		dropdownItems,
		dropdownRef,
		isDropdownActive,
		makeTabRefCallback,
		shouldRenderDropdown,
		shownTabsCount,
		tabBarRef,
	};
};
