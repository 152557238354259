import { ReactNode, memo, useCallback, useEffect, useMemo, useRef } from 'react';

import {
	Spacing,
	VerticalSpacing,
	resolveVerticalPadding,
	visuallyHiddenStyle,
} from '@creditinfo-ui/atoms';
import { prepareStyle, useStyles } from '@creditinfo-ui/styles';

import {
	CurrentTabControls,
	CurrentTabControlsContext,
	TabControls,
	TabControlsContext,
} from '../hooks';
import { HorizontalInset, TabKey } from '../types';
import { resolveHorizontalInset } from '../utils';
import { CURRENT_TAB_CONTROLS_DOM_ATTRIBUTE } from '../constants';

interface TabContentElement extends HTMLDivElement {
	[CURRENT_TAB_CONTROLS_DOM_ATTRIBUTE]?: CurrentTabControls;
}

interface TabContentStyleProps {
	hasInsetShadow: boolean;
	horizontalInset: HorizontalInset;
	paddingBottom: Spacing;
	paddingTop: Spacing;
}

const TAB_CONTENT_SHADOW = 'inset 0 10px 15px -5px rgb(0 0 0 / 4%)';

const tabContentStyle = prepareStyle<TabContentStyleProps>(
	(utils, { hasInsetShadow, horizontalInset, paddingBottom, paddingTop }) => ({
		...resolveHorizontalInset(horizontalInset, utils),
		extend: [
			{
				condition: hasInsetShadow,
				style: {
					boxShadow: TAB_CONTENT_SHADOW,
				},
			},
			{
				condition: paddingBottom !== 'none',
				style: {
					paddingBottom: utils.spacings[paddingBottom],
				},
			},
			{
				condition: paddingTop !== 'none',
				style: {
					paddingTop: utils.spacings[paddingTop],
				},
			},
		],
	})
);

export interface TabContentProps {
	children: ReactNode;
	hasInsetShadow: boolean;
	horizontalInset: HorizontalInset;
	isActive: boolean;
	isFirst: boolean;
	isLast: boolean;
	next: () => void;
	previous: () => void;
	setActiveKey: (key: TabKey) => void;
	tabKey: TabKey;
	verticalPadding: VerticalSpacing;
}

const PureTabContent = ({
	hasInsetShadow,
	horizontalInset,
	children,
	isActive,
	isFirst,
	isLast,
	next,
	previous,
	setActiveKey,
	tabKey,
	verticalPadding,
}: TabContentProps) => {
	const { applyStyle } = useStyles();
	const rootRef = useRef<TabContentElement>(null);

	const { paddingBottom, paddingTop } = resolveVerticalPadding(verticalPadding);

	const tabControls: TabControls = useMemo(
		() => ({ isFirst, isLast, isVisible: isActive, next, previous, setActiveKey }),
		[isActive, isFirst, isLast, next, previous, setActiveKey]
	);

	const activate = useCallback(() => setActiveKey(tabKey), [setActiveKey, tabKey]);

	const currentTabControls: CurrentTabControls = useMemo(
		() => ({ activate, isActive, isFirst, isLast, key: tabKey }),
		[activate, isActive, isFirst, isLast, tabKey]
	);

	useEffect(() => {
		if (rootRef.current) {
			rootRef.current[CURRENT_TAB_CONTROLS_DOM_ATTRIBUTE] = currentTabControls;
		}
	}, [currentTabControls]);

	return (
		<TabControlsContext.Provider value={tabControls}>
			<CurrentTabControlsContext.Provider value={currentTabControls}>
				<div
					ref={rootRef}
					role="tabpanel"
					className={applyStyle([tabContentStyle, isActive ? null : visuallyHiddenStyle], {
						hasInsetShadow,
						horizontalInset,
						paddingBottom,
						paddingTop,
					})}
				>
					{children}
				</div>
			</CurrentTabControlsContext.Provider>
		</TabControlsContext.Provider>
	);
};

export const TabContent = memo(PureTabContent);
