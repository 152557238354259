import { useCallback, useEffect, useRef } from 'react';

type IntervalId = ReturnType<typeof setInterval>;

export const useInterval = (callback, interval) => {
	const callbackRef = useRef<() => void>();
	const intervalRef = useRef<IntervalId | null>(null);

	// ensure cleanup on unmount
	useEffect(
		() => () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		},
		[interval]
	);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const tick = useCallback(() => {
		if (callbackRef.current) {
			callbackRef.current();
		}
	}, []);

	const startInterval = useCallback(() => {
		intervalRef.current = setInterval(tick, interval);
	}, [tick, interval]);

	const resetInterval = useCallback(() => {
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}
	}, []);

	return [startInterval, resetInterval];
};
