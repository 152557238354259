import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AdministrativeUnit,
	UserType,
	fetchCountrySubdivisions,
	makeSelectCountrySubdivisionsByUserType,
} from './duck';

const emptyResult = [];

export const useCountrySubdivisions = (userType: UserType): AdministrativeUnit[] => {
	const dispatch = useDispatch();
	const countrySubdivisions = useSelector(makeSelectCountrySubdivisionsByUserType(userType));

	useEffect(() => {
		if (!countrySubdivisions) {
			dispatch(fetchCountrySubdivisions(userType));
		}
	}, [userType]);

	return countrySubdivisions ?? emptyResult;
};
