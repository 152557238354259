import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Message } from '@myci/intl';
import { useGeneratedId } from '@ci/react-utils';

import FormField from '../FormField';
import SelectControl from '../SelectControl';

import './Select.scss';

const Select = ({ id, label, ...otherProps }) => {
	const nextLabel =
		label?.type === Message ? cloneElement(label, { shouldObfuscate: true }) : label;

	// NOTE: Just like the message obfuscation, this is necessary to disable browser autofill.
	// Otherwise, `FormField` would include `name` in the ID, giving the browser too much info.
	const generatedId = useGeneratedId();
	const labelId = `label-${id ?? generatedId}`;

	return (
		<FormField
			control={SelectControl}
			kind="select"
			autoComplete="off"
			label={nextLabel}
			// NOTE: `FormField` doesn't know about this prop, so it's passed down to `Select`.
			labelId={labelId}
			labelProps={{ id: labelId }}
			id={id ?? `select-${generatedId}`}
			{...otherProps}
		/>
	);
};

Select.propTypes = {
	id: PropTypes.string,
	label: PropTypes.node,
};

export default Select;
