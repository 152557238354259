import { makeFormatter } from 'afformative';
import { useSelector } from 'react-redux';
import { getEntities } from '@ci/entities';
import { path, o, defaultTo } from 'ramda';
import { ensureArray } from '@ci/utils';
import { useCallback, useMemo } from 'react';

export const useSelectorFormatter = (
	getReduxSlice,
	getSliceByValue,
	displayName = 'SelectorFormatter'
) => {
	const reduxSlice = useSelector(getReduxSlice);

	return useMemo(
		() => makeFormatter(getSliceByValue(reduxSlice), displayName),
		[getSliceByValue, reduxSlice, displayName]
	);
};

export const useEntityFormatter = (entityKey, labelAttribute, displayName = 'EntityFormatter') => {
	const getSliceByValue = useCallback(
		entities => id => o(defaultTo(id), path([id, ...ensureArray(labelAttribute)]))(entities),
		[entityKey, labelAttribute]
	);

	return useSelectorFormatter(getEntities(entityKey), getSliceByValue, displayName);
};
