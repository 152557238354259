import { createThunk, download, isFetching, request } from '@ci/api';
import { InstanceTypes, currentInstanceType } from '@myci/utils';
import { addToast } from '@ci/toasts';
import { createAction, createReducer } from '@reduxjs/toolkit';
import m from './messages';

export const scanResults = {
	notSpecified: 0,
	threatDetected: 1,
	notDetected: 2,
	applicationError: 3,
} as const;

export type ScanResult = keyof typeof scanResults;

export interface AntimalwareScanDetail {
	correlationID: string;
	modification: string;
	result: typeof scanResults[ScanResult];
}

export interface AttachmentInfo {
	attachmentType: number;
	attachmentUseCaseId: number;
	fileName: string;
	mediaType: string;
	sensitivity: number;
}

export interface AttachmentScanResult {
	antimalwareScanDetail: AntimalwareScanDetail;
	attachmentInfo: AttachmentInfo;
	chunksNumber: number;
	disabled: boolean;
	id: string;
	size: number;
}

export interface AttachmentsState {
	scanResults: Record<string, AttachmentScanResult>;
}

interface GlobalState {
	attachments: AttachmentsState;
}

const storeAttachmentScanResult = createAction<{
	attachmentIdentifier: string;
	scanResult: AttachmentScanResult;
}>('@attachments/storeAttachmentScanResult');

const initialState: AttachmentsState = {
	scanResults: {},
};

const isBoInstance = currentInstanceType === InstanceTypes.INSTANCE_BO;

export const scanAttachment = createThunk(
	{ originType: '@attachments/scanAttachment', errorMessage: m.scanError },
	async ({ dispatch }, attachmentIdentifier: string) => {
		const successAction = await dispatch(
			request({
				url: `/attachment/topic/${attachmentIdentifier}/MyciAttachment`,
				method: 'GET',
			})
		);

		dispatch(
			storeAttachmentScanResult({
				attachmentIdentifier,
				scanResult: isBoInstance
					? successAction.payload.attachments[0]
					: successAction.payload.result.data.attachments[0],
			})
		);
	}
);

export const downloadAttachment = createThunk(
	'@attachments/downloadAttachment',
	async ({ dispatch }, attachmentMetadata: AttachmentScanResult) => {
		const {
			chunksNumber,
			id,
			attachmentInfo: { fileName },
		} = attachmentMetadata;

		try {
			dispatch(
				download({
					url: `/attachment/data/${id}/:chunk`,
					chunkCount: chunksNumber,
					filename: fileName,
				})
			);
		} catch {
			dispatch(
				addToast({
					type: 'warning',
					content: m.downloadError,
				})
			);
		}
	}
);

export const selectIsFetchingScanAttachment = isFetching(scanAttachment.originType);
export const selectAttachmentScanResults = (state: GlobalState) => state?.attachments?.scanResults;
export const makeSelectAttachmentScanResult =
	(attachmentIdentifier: string) => (state: GlobalState) =>
		selectAttachmentScanResults(state)?.[attachmentIdentifier];

export const reducer = createReducer(initialState, builder => {
	builder.addCase(storeAttachmentScanResult, (state, action) => ({
		...state,
		scanResults: {
			...state.scanResults,
			[action.payload.attachmentIdentifier]: action.payload.scanResult,
		},
	}));
});
