import React from 'react';
import PropTypes from 'prop-types';
import { cx } from 'ramda-extension';

import Box from '../Box';
import AccordionHeader from '../AccordionHeader';
import AccordionBody from '../AccordionBody';

const AccordionPanel = ({
	isOpen,
	className,
	children,
	bodyClassName,
	headerClassName,
	isContentFormatted,
	...rest
}) => (
	<Box className={cx('accordion__item', { 'accordion__item--active': isOpen }, className)}>
		<AccordionHeader isOpen={isOpen} className={headerClassName} {...rest} />
		<AccordionBody
			className={cx(bodyClassName, { 'accordion__item-body--formatted': isContentFormatted })}
		>
			{children}
		</AccordionBody>
	</Box>
);

AccordionPanel.propTypes = {
	bodyClassName: PropTypes.string,
	/** Children to be rendered in the main container. */
	children: PropTypes.node,
	/** Class name for root. */
	className: PropTypes.string,
	headerClassName: PropTypes.string,
	isContentFormatted: PropTypes.bool,
	/** Is panel open indicator. */
	isOpen: PropTypes.bool,
};

export default AccordionPanel;
