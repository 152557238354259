import { makeActionTypes, makeConstantActionCreator } from 'redux-syringe';
import { composeMiddleware, makeMiddleware, typeEq } from '@myci/utils';

import { Storage } from './storage';
import { storageKeys } from './constants';

export const ActionTypes = makeActionTypes('@myci/state', ['CLEAR_STATE']);

export const clearState = makeConstantActionCreator(ActionTypes.CLEAR_STATE);

const clearStateMiddleware = makeMiddleware(typeEq(ActionTypes.CLEAR_STATE), () => () => {
	Storage.removeItem(storageKeys.ACCESS_TOKEN_KEY);
	Storage.removeItem(storageKeys.REFRESH_TOKEN_KEY);
	Storage.removeItem(storageKeys.MYCI_STORE_KEY);
	Storage.removeItem(storageKeys.PUSH_NOTIFICATIONS_TOKEN);
	Storage.removeItem(storageKeys.TEMP_REQUESTED_ORDER_DATA);
});

export const middleware = composeMiddleware(clearStateMiddleware);
