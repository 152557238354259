import { withReducers } from 'redux-syringe';
import { reverse } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reducer, removeToast, selectToasts } from './duck';
import { Toast } from './Toast';
import { ToastStack } from './ToastStack';
import { ToastId } from './types';

export interface ToastsProps {
	onExpand?: (expansionId: string) => void;
}

const PureToasts = ({ onExpand }: ToastsProps) => {
	const dispatch = useDispatch();
	const toasts = useSelector(selectToasts);
	const stack = toasts?.stack;
	const reversedToasts = useMemo(() => reverse(stack ?? []), [stack]);

	const handleClose = useCallback(
		(toastId: ToastId) => {
			dispatch(removeToast(toastId));
		},
		[dispatch]
	);

	return (
		<ToastStack
			toasts={reversedToasts}
			renderToast={props => <Toast onClose={handleClose} onExpand={onExpand} {...props} />}
		/>
	);
};

export const Toasts = withReducers({ toasts: reducer })(PureToasts);
