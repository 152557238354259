import { isNotEmpty } from 'ramda-extension';
import { forEach, both, complement, allPass, either } from 'ramda';
import { makeActionTypes, makeConstantActionCreator, makeSimpleActionCreator } from 'redux-syringe';
import {
	getIsAnyRequestAction,
	getRequest,
	getIsErrorResponseAction,
	getIsRemovePendingRequestAction,
	IGNORE_RESPONSE_ACTION,
} from '@ci/api';
import { makeMiddleware, composeMiddleware, typeEq } from '@ci/utils';

import { getIsTokenExpired, setIsBuffered, getIsNotBuffered, getIsBuffered } from './utils';

export const actionTypes = makeActionTypes('@authentication', [
	'buffering',
	'flushing',
	'ignoring',
	'flushRequests',
]);

const bufferingEvent = makeSimpleActionCreator(actionTypes.buffering);
const flushingEvent = makeSimpleActionCreator(actionTypes.flushing);
const ignoringEvent = makeSimpleActionCreator(actionTypes.ignoring);

export const flushRequests = makeConstantActionCreator(actionTypes.flushRequests);

/**
 * Creates a Redux middleware for automatic injection of access tokens into AJAX requests.
 *
 * See `/docs/concepts/authentication.md` for more information.
 *
 * @param {Object} options authentication middleware options
 */
export const makeAuthenticationMiddleware = ({
	attachAccessToken,
	selectAccessToken,
	getAuthorizationCode,
	selectRefreshToken,
	fetchAccessToken,
	fetchRefreshToken,
	getIsAuthenticationRequest,
	getIsAuthenticationError,
	selectIsAuthenticating,
	getIsLogoutRequest,
	getIsTokenRequired,
	logOut,
	getIncomingPkceState,
	getStoredPkceState,
}) => {
	const bufferedRequests = [];

	const getIsNotLogoutRequest = complement(getIsLogoutRequest);

	const getIsRepeatableRequest = allPass([
		getIsNotBuffered,
		complement(getIsAuthenticationRequest),
		getIsNotLogoutRequest,
	]);

	/**
	 * Procedure: adds a request to the buffer.
	 *
	 * @param {Object} reduxAPI the Redux API
	 * @param {Action} request request action to buffer
	 */
	const bufferRequest = (reduxAPI, request) => {
		const { dispatch } = reduxAPI;

		dispatch(bufferingEvent(request));
		bufferedRequests.push(request);
	};

	/**
	 * Procedure: performs authentication and retries the request.
	 *
	 * @param {Object} reduxAPI the Redux API
	 * @param {Action} request request action to retry
	 * @returns {boolean} whether the request will be retried with a valid access token.
	 */
	const retryAuthenticated = (reduxAPI, request) => {
		const { dispatch, getState } = reduxAPI;
		const state = getState();

		if (selectIsAuthenticating(state)) {
			bufferRequest(reduxAPI, request);

			return true;
		}

		if (!selectRefreshToken(state)) {
			dispatch(logOut());

			return false;
		}

		bufferRequest(reduxAPI, request);
		dispatch(fetchAccessToken(selectRefreshToken(state)));

		return true;
	};

	/**
	 * Middleware for intercepting requests and buffering/authenticating them if necessary.
	 */
	const requestMiddleware = reduxAPI => next => action => {
		if (!getIsAnyRequestAction(action)) {
			return next(action);
		}

		const { dispatch, getState } = reduxAPI;
		const state = getState();

		const accessToken = selectAccessToken(state);

		if (getIsLogoutRequest(action)) {
			return next(attachAccessToken(accessToken, action));
		}

		if (getIsAuthenticationRequest(action)) {
			if (selectIsAuthenticating(state)) {
				return dispatch(ignoringEvent(action));
			}

			return next(action);
		}

		if (selectIsAuthenticating(state)) {
			return bufferRequest(reduxAPI, action);
		}

		if (accessToken) {
			if (getIsTokenExpired(accessToken)) {
				return retryAuthenticated(reduxAPI, action);
			}

			return next(attachAccessToken(accessToken, action));
		}

		if (selectRefreshToken(state)) {
			return retryAuthenticated(reduxAPI, action);
		}

		if (getIsTokenRequired(state, action)) {
			dispatch(ignoringEvent(action));

			return dispatch(logOut());
		}

		return next(action);
	};

	/**
	 * Middleware for retrying requests which have failed due to an authentication error.
	 */
	const authenticationErrorMiddleware = reduxAPI => next => action => {
		if (!getIsErrorResponseAction(both(getIsRepeatableRequest, getIsAuthenticationError), action)) {
			return next(action);
		}

		const innerMiddlewareReturnValue = next(action);
		const willRetry = retryAuthenticated(reduxAPI, getRequest(action));

		if (willRetry) {
			// NOTE: This tells `promiseLikeRequestMiddleware`, i.e. the outer middleware, not to
			// consider this action for settling promises. Authentication middleware guarantees
			// that the request will be retried if we receive a valid access token (otherwise the
			// user will be logged out), meaning that the promise will be settled eventually.
			return IGNORE_RESPONSE_ACTION;
		}

		return innerMiddlewareReturnValue;
	};

	/**
	 * Middleware for authentication errors of buffered requests.
	 */
	const bufferedAuthenticationErrorMiddleware = makeMiddleware(
		getIsErrorResponseAction(both(getIsBuffered, getIsAuthenticationError)),
		({ dispatch }) =>
			errorAction =>
				dispatch(logOut(errorAction))
	);

	/**
	 * Middleware for flushing buffered requests when a new access token is available.
	 *
	 * When the resolution of the access token is delegated to another application, `flushRequests`
	 * should be dispatched manually once the token is stored and `selectIsAuthenticating` would
	 * return `false`.
	 */
	const fetchAnyTokenSuccessMiddleware = makeMiddleware(
		either(
			// NOTE: We cannot use `getIsSuccessResponseAction` because our buffering mechanism relies on
			// `selectIsAuthenticating`. However, at the time of dispatching `successResponseAction`,
			// the selector could still return `true`, causing the requests to be buffered again,
			// indefinitely. Since `@ci/api` dispatches `removePendingRequestAction` asynchronously,
			// we can rely on this action, assuming `isAuthenticating` is updated synchronously.
			getIsRemovePendingRequestAction(getIsAuthenticationRequest),
			typeEq(actionTypes.flushRequests)
		),
		({ dispatch }) =>
			() => {
				const flushedRequests = [];

				// NOTE: We copy and clear the array to avoid infinite loops.
				while (isNotEmpty(bufferedRequests)) {
					flushedRequests.push(bufferedRequests.shift());
				}

				dispatch(flushingEvent(flushedRequests));
				forEach(request => dispatch(setIsBuffered(request)), flushedRequests);
			}
	);

	/**
	 * Middleware for handling errors after fetching a token.
	 */
	const fetchAnyTokenErrorMiddleware = makeMiddleware(
		getIsErrorResponseAction(getIsAuthenticationRequest),
		({ dispatch }) =>
			errorAction =>
				// TODO: Dispatch fake error actions for all buffered requests so that all promises
				// are settled. This will be useful in case `logOut()` doesn't redirect the user
				// out of the application, e.g. inside of the identity service. Implementation-wise,
				// `dispatch(logOut())` shouldn't be called directly, but rather as a part of a new
				// procedure so that all calls to `logOut()` perform this cleanup.
				dispatch(logOut(errorAction))
	);

	const middleware = composeMiddleware(
		requestMiddleware,
		authenticationErrorMiddleware,
		bufferedAuthenticationErrorMiddleware,
		fetchAnyTokenSuccessMiddleware,
		fetchAnyTokenErrorMiddleware
	);

	middleware.initialize = store => {
		const state = store.getState();
		const authorizationCode = getAuthorizationCode();
		const refreshToken = selectRefreshToken(state);

		if (authorizationCode) {
			if (getStoredPkceState(state) !== getIncomingPkceState(state)) {
				return store.dispatch(logOut());
			}

			return store.dispatch(fetchRefreshToken(authorizationCode));
		}

		if (refreshToken) {
			return store.dispatch(fetchAccessToken(refreshToken));
		}
	};

	// NOTE: This is necessary for tests.
	middleware.reset = () => {
		while (isNotEmpty(bufferedRequests)) {
			bufferedRequests.pop();
		}
	};

	return middleware;
};
