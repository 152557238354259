import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Message } from '@creditinfo-ui/messages';
import { mergeStyles, prepareStyle, prepareStyleFactory } from '@creditinfo-ui/styles';
import { Icon, Menu, MenuItem, Popover } from '@creditinfo-ui/atoms';
import { TabButton } from './TabButton';
import { StepperNumber } from './StepperNumber';
import { TabButtonLabel } from './TabButtonLabel';
import { defaultTabButtonStyle } from './DefaultTabButton';
import { m } from '../messages';
import { TabItem, TabKey, TabsVariant } from '../types';

export interface TabDropdownProps {
	activeIndex: number;
	activeKey: TabKey;
	isActive: boolean;
	items: TabItem[];
	onTabClick: (key: TabKey) => void;
	shownTabsCount: number;
	variant: TabsVariant;
}

const tabButtonCustomStyle = prepareStyle(() => ({
	cursor: 'pointer',
}));

const caretIconCustomStyle = prepareStyleFactory<{ isOpen: boolean }>((utils, { isOpen }) => ({
	marginInlineStart: utils.spacings.sm,
	transform: isOpen ? 'rotate(180deg)' : undefined,
	transitionDuration: utils.transitions.speeds.default,
	transitionProperty: 'transform',
	transitionTimingFunction: utils.transitions.easing,
}));

const menuItemCustomStyle = prepareStyleFactory<{
	isActive: boolean;
	isDisabled: boolean;
}>((utils, { isActive, isDisabled }) => ({
	alignItems: 'center',
	color: utils.colors.gray700,
	display: 'flex',
	fontSize: utils.fontSizes.tabLabel,
	fontWeight: isActive ? utils.fontWeights.extraBold : utils.fontWeights.normal,
	lineHeight: utils.lineHeights.base,
	userSelect: 'none',
	whiteSpace: 'nowrap',
	extend: [
		{
			condition: isActive,
			style: {
				cursor: 'default',
			},
		},
		{
			condition: !isActive && !isDisabled,
			style: {
				selectors: {
					':hover': {
						color: utils.colors.primary,
					},
				},
			},
		},
	],
}));

export const TabDropdown = forwardRef<HTMLButtonElement, TabDropdownProps>(
	({ activeIndex, activeKey, isActive, items, onTabClick, shownTabsCount, variant }, ref) => {
		const [isVisible, setIsVisible] = useState(false);

		const handlePopoverShow = useCallback(() => {
			setIsVisible(true);
		}, []);

		const handlePopoverHide = useCallback(() => {
			setIsVisible(false);
		}, []);

		const icon = useMemo(
			() => (
				<Icon
					isLabeled
					type="caretDown"
					color="black"
					size="sm"
					customStyle={caretIconCustomStyle({ isOpen: isVisible })}
				/>
			),
			[isVisible]
		);

		return (
			<Popover
				onHide={handlePopoverHide}
				onShow={handlePopoverShow}
				popover={
					<Menu>
						{items.map((dropdownItem, dropdownItemIndex) => {
							const { label, key } = dropdownItem;
							const index = shownTabsCount + dropdownItemIndex;
							const isItemActive = activeKey === key && isActive;
							const isDisabled =
								dropdownItem.isDisabled || (variant === 'stepper' && index > activeIndex);

							const handleClick = () => {
								onTabClick(key);
							};

							return (
								<MenuItem
									key={key}
									onClick={isDisabled ? undefined : handleClick}
									isDisabled={isDisabled}
									customStyle={menuItemCustomStyle({ isActive: isItemActive, isDisabled })}
								>
									{variant === 'stepper' && (
										<StepperNumber
											activeIndex={activeIndex}
											index={index}
											isActive={isItemActive}
										/>
									)}
									{label}
								</MenuItem>
							);
						})}
					</Menu>
				}
			>
				<TabButton
					ref={ref}
					icon={icon}
					isActive={isActive}
					isDisabled={false}
					isInvalid={false}
					customStyle={mergeStyles([defaultTabButtonStyle, tabButtonCustomStyle])}
				>
					<TabButtonLabel>
						<Message {...m.more} />
					</TabButtonLabel>
				</TabButton>
			</Popover>
		);
	}
);

TabDropdown.displayName = 'TabDropdown';
