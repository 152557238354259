import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { T, always, cond, includes, prop } from 'ramda';
import { cx, isNilOrEmpty, isNumber } from 'ramda-extension';
import { IdentityFormatter } from '@ci/formatters';
import { stringOrNumberPropType } from '@ci/react-utils';

import FormControl from '../FormControl';
import { SelectControlAutocomplete } from '../SelectControlAutocomplete';
import SelectControlStatic from '../SelectControlStatic';
import Multiselect from '../Multiselect';

const SelectControl = forwardRef(
	(
		{
			className,
			autocomplete = true,
			// TODO: Replace these props with a `singleValueStrategy` when refactoring selects.
			singleValueDisabled = false,
			singleValueSelected = true,
			items,
			disabled,
			onChange,
			value,
			defaultValue,
			...otherProps
		},
		ref
	) => {
		const { statics, multiple } = otherProps;
		const onlyOneItem = items && items.length === 1;
		const onlyOneStaticItem = (!items || items.length === 0) && statics?.length === 1;

		useEffect(() => {
			if (
				!multiple &&
				onlyOneItem &&
				(singleValueDisabled || isNilOrEmpty(statics)) &&
				value !== items[0] &&
				singleValueSelected
			) {
				onChange(items[0]);
			} else if (isNumber(value) && !includes(value, items)) {
				onChange(defaultValue);
			}
		}, [items, value]);

		useEffect(() => {
			if (!value && defaultValue) {
				onChange(defaultValue);
			}
		}, []);

		return (
			<FormControl
				ref={ref}
				className={cx(className)}
				component={cond([
					// TODO: This resolution should be handled based on a `type` or `kind` prop.
					[prop('multiple'), always(Multiselect)],
					[always(autocomplete), always(SelectControlAutocomplete)],
					[T, always(SelectControlStatic)],
				])(otherProps)}
				{...otherProps}
				items={items}
				onChange={onChange}
				disabled={singleValueDisabled ? onlyOneItem || onlyOneStaticItem || disabled : disabled}
				placeholder={null}
				value={value}
			/>
		);
	}
);

SelectControl.displayName = 'SelectControl';

SelectControl.propTypes = {
	autocomplete: PropTypes.bool,
	className: PropTypes.string,
	defaultValue: PropTypes.any,
	disabled: PropTypes.bool,
	items: PropTypes.arrayOf(stringOrNumberPropType).isRequired,
	onChange: PropTypes.func,
	singleValueDisabled: PropTypes.bool,
	singleValueSelected: PropTypes.bool,
	value: PropTypes.any,
};

SelectControl.defaultProps = {
	formatter: IdentityFormatter,
};

export default SelectControl;
