import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNilOrEmptyString } from 'ramda-extension';

import { fetchInstanceConfiguration, selectInstanceConfig } from './duck';

export const useRemoteInstanceConfig = () => {
	const dispatch = useDispatch();
	const instanceConfig = useSelector(selectInstanceConfig);

	useEffect(() => {
		if (isNilOrEmptyString(instanceConfig)) {
			dispatch(fetchInstanceConfiguration());
		}
	}, [instanceConfig]);
	return instanceConfig;
};
