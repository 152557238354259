import { ReactNode } from 'react';
import { Style, prepareStyle, useStyles } from '@creditinfo-ui/styles';
import { Breakpoint } from '@creditinfo-ui/fela';
import { Spacing, VerticalSpacing, resolveVerticalMargin } from '@creditinfo-ui/atoms';

type Layout = 'column' | 'row';

export interface SelectionControlsStyleProps {
	layout: Layout;
	marginBottom: Spacing;
	marginTop: Spacing;
	mobileLayout: Layout;
	mobileLayoutBreakpoint: Breakpoint;
}

const selectionControlsStyle = prepareStyle<SelectionControlsStyleProps>(
	(utils, { layout, mobileLayout, mobileLayoutBreakpoint, marginBottom, marginTop }) => ({
		display: 'flex',
		flexDirection: mobileLayout,
		selectors: {
			[utils.breakpoints[mobileLayoutBreakpoint]]: {
				flexDirection: layout,
			},
		},
		extend: [
			{
				condition: marginBottom !== 'none',
				style: {
					marginBottom: utils.spacings[marginBottom],
				},
			},
			{
				condition: marginTop !== 'none',
				style: {
					marginTop: utils.spacings[marginTop],
				},
			},
		],
	})
);

const controlWrapperStyle = prepareStyle<{
	layout: Layout;
	mobileLayout: Layout;
	mobileLayoutBreakpoint: Breakpoint;
}>((utils, { layout, mobileLayout, mobileLayoutBreakpoint }) => ({
	extend: [
		{
			condition: mobileLayout === 'column',
			style: {
				selectors: {
					':not(:first-child)': {
						marginTop: utils.spacings.sm,
					},
				},
			},
		},
		{
			condition: mobileLayout === 'row',
			style: {
				selectors: {
					':not(:first-child)': {
						marginInlineStart: utils.spacings.sm,
					},
				},
			},
		},
		{
			condition: layout === 'column',
			style: {
				selectors: {
					[utils.breakpoints[mobileLayoutBreakpoint]]: {
						selectors: {
							':not(:first-child)': {
								marginInlineStart: 0,
								marginTop: utils.spacings.sm,
							},
						},
					},
				},
			},
		},
		{
			condition: layout === 'row',
			style: {
				selectors: {
					[utils.breakpoints[mobileLayoutBreakpoint]]: {
						selectors: {
							':not(:first-child)': {
								marginInlineStart: utils.spacings.sm,
								marginTop: 0,
							},
						},
					},
				},
			},
		},
	],
}));

export interface SelectionControlsProps {
	controls: ReactNode[];
	customStyle?: Style<SelectionControlsStyleProps>;
	layout?: Layout;
	mobileLayout?: Layout;
	mobileLayoutBreakpoint?: Breakpoint;
	verticalMargin?: VerticalSpacing;
}

export const SelectionControls = ({
	controls,
	customStyle,
	layout = 'row',
	mobileLayout = 'column',
	mobileLayoutBreakpoint = 'sm',
	verticalMargin = 'none',
}: SelectionControlsProps) => {
	const { applyStyle } = useStyles();
	const { marginBottom, marginTop } = resolveVerticalMargin(verticalMargin);

	return (
		<div
			className={applyStyle([selectionControlsStyle, customStyle], {
				layout,
				mobileLayout,
				mobileLayoutBreakpoint,
				marginBottom,
				marginTop,
			})}
		>
			{controls.filter(Boolean).map((control, controlIndex) => (
				<div
					className={applyStyle(controlWrapperStyle, {
						layout,
						mobileLayout,
						mobileLayoutBreakpoint,
					})}
					key={controlIndex}
				>
					{control}
				</div>
			))}
		</div>
	);
};
