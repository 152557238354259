import {
	ForwardedRef,
	MouseEventHandler,
	ReactElement,
	RefAttributes,
	forwardRef,
	useCallback,
	useRef,
} from 'react';
import { UseSelectStateChange, useSelect } from 'downshift';
import { Formatter } from 'afformative';
import { isNil } from 'ramda';
import {
	Style,
	StyleObject,
	mergeStyles,
	prepareStyle,
	prepareStyleFactory,
	useStyles,
} from '@creditinfo-ui/styles';
import { mergeRefs } from '@creditinfo-ui/utils';
import { Menu } from './Menu';
import { MenuItem } from './MenuItem';
import { identityFormatter } from '../utils';
import { Icon } from './Icon';
import { Button, ButtonStyleProps } from './Button';
import { useDownshiftFormatter, useOpenDirection } from '../hooks';
import { NilMessage } from '../types';
import { m } from '../messages';

type DropdownVariant = 'primary' | 'secondary' | 'subtle';

export interface DropdownStyleProps {
	isOpen: boolean;
	isSubtle: boolean;
}

const DROPDOWN_MIN_WIDTH = '150px';

const dropdownStyle = prepareStyle<DropdownStyleProps>((utils, { isOpen, isSubtle }) => ({
	borderRadius: utils.borders.radii.basic,
	position: 'relative',
	transitionDuration: utils.transitions.speeds.faster,
	transitionProperty: 'box-shadow',
	transitionTimingFunction: utils.transitions.easing,
	extend: [
		{
			condition: isOpen,
			style: {
				boxShadow: utils.boxShadows.menu,
			},
		},
		{
			condition: !isSubtle,
			style: {
				minWidth: DROPDOWN_MIN_WIDTH,
			},
		},
	],
}));

const buttonsWrapperStyle = prepareStyle(() => ({
	display: 'flex',
	width: '100%',
}));

const HORIZONTAL_CARET_PADDING = '1.5rem';

interface DropdownButtonStyleProps {
	isOpen: boolean;
	isSplitButton: boolean;
	isSubtle: boolean;
}

const dropdownButtonStyle = prepareStyleFactory<DropdownButtonStyleProps, ButtonStyleProps>(
	(utils, { isOpen, isSplitButton, isSubtle }, { isDisabled }) => {
		const focusStyleObject: StyleObject = {
			backgroundColor: 'white',
			zIndex: Number(utils.zIndices.menu) + 1,
			extend: {
				condition: isSubtle,
				style: {
					borderColor: utils.colors.gray800,
					boxShadow: utils.boxShadows.focusInset(utils.transparentize(0.7, utils.colors.primary)),
					color: utils.colors.gray600,
				},
			},
		};

		return {
			flexGrow: 1,
			justifyContent: 'space-between',
			position: 'relative',
			selectors: {
				':focus': focusStyleObject,
			},
			extend: [
				{
					condition: isOpen,
					style: focusStyleObject,
				},
				{
					condition: !isSubtle && !isSplitButton,
					style: {
						paddingInlineEnd: HORIZONTAL_CARET_PADDING,
					},
				},
				{
					condition: isSubtle && !isDisabled,
					style: {
						selectors: {
							':hover': {
								backgroundColor: 'white',
								borderColor: utils.colors.gray800,
								color: utils.colors.gray600,
							},
							':active': {
								color: utils.colors.gray600,
							},
						},
					},
				},
			],
		};
	}
);

const valueSplitButtonStyle = prepareStyleFactory<{ isOpen: boolean }, ButtonStyleProps>(
	(utils, { isOpen }, { isBusy }) => ({
		borderEndEndRadius: 0,
		borderStartEndRadius: 0,

		extend: [
			{
				condition: isOpen,
				style: {
					// NOTE: The `Menu` appears instantly when the trigger button is clicked, resulting in a visual
					// glitch due to the buttons having a transparent background by default. There is no issue with
					// regular (non-split) dropdowns, since the trigger button always has hover/focus styles.
					transitionDuration: '0s',
				},
			},
			{
				condition: isBusy,
				style: {
					paddingInlineEnd: 0,
				},
			},
		],
	})
);

const caretSplitButtonStyle = prepareStyle(utils => ({
	borderEndStartRadius: 0,
	borderInlineStartWidth: 0,
	borderStartStartRadius: 0,
	flexBasis: utils.spacings.lg,
	flexGrow: 0,
	minWidth: utils.spacings.lg,
	paddingLeft: 0,
	paddingRight: 0,
}));

interface CaretIconStyleProps {
	isOpen: boolean;
	isSplitButton: boolean;
	isSubtle: boolean;
}

const caretIconStyle = prepareStyleFactory<CaretIconStyleProps>(
	(utils, { isOpen, isSplitButton, isSubtle }) => ({
		pointerEvents: 'none',
		transform: isOpen ? 'rotate(180deg)' : undefined,
		transitionDuration: utils.transitions.speeds.slower,
		transitionProperty: 'transform',
		transitionTimingFunction: utils.transitions.easing,

		extend: [
			{
				condition: !isSplitButton,
				style: {
					marginInlineStart: isSubtle ? utils.spacings.sm : HORIZONTAL_CARET_PADDING,
				},
			},
			{
				condition: isSplitButton,
				style: {
					marginLeft: 'auto',
					marginRight: 'auto',
				},
			},
		],
	})
);

const menuCustomStyle = prepareStyleFactory<{ isOpen: boolean }>((utils, { isOpen }) => ({
	borderColor: 'transparent',
	borderStyle: 'solid',
	borderWidth: utils.borders.widths.md,

	extend: {
		condition: !isOpen,
		style: {
			// NOTE: We're relying on `scrollHeight` to calculate the menu height when closed.
			height: 0,
			overflowY: 'hidden',
			visibility: 'hidden',
		},
	},
}));

// NOTE: `Object` means any value except for `null` and `undefined`.
export interface DropdownProps<TItem extends Object> {
	customStyle?: Style<DropdownStyleProps>;
	formatter?: Formatter<TItem, any, string>;
	isBusy?: boolean;
	isDisabled?: boolean;
	isSplitButton?: boolean;
	items: TItem[];
	/**
	 * Message to use when an item or the `value` prop is `null` or `undefined`.
	 * Pass `false` to delegate nil formatting logic to the `formatter` prop.
	 */
	nilMessage?: NilMessage;
	/** `onClick` is called only when `isSplitButton` is `true`. */
	onClick?: MouseEventHandler<HTMLButtonElement>;
	onSelect: (item: TItem) => void;
	value?: TItem | null;
	variant?: DropdownVariant;
}

const UntypedDropdown = forwardRef(
	<TItem extends Object>(
		{
			customStyle,
			formatter: formatterProp = identityFormatter,
			isBusy = false,
			isDisabled = false,
			isSplitButton = false,
			items,
			nilMessage = m.emptyValue,
			onClick,
			onSelect,
			value,
			variant = 'subtle',
		}: DropdownProps<TItem>,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { applyStyle } = useStyles();
		const handleSelectedItemChange = useCallback(
			({ selectedItem }: UseSelectStateChange<TItem>) => {
				if (!isNil(selectedItem) && onSelect) {
					onSelect(selectedItem);
				}
			},
			[onSelect]
		);

		const isSubtle = variant === 'subtle';
		const formatter = useDownshiftFormatter(formatterProp, nilMessage);

		const { getMenuProps, getItemProps, getToggleButtonProps, isOpen, highlightedIndex } =
			useSelect({
				items,
				itemToString: formatter.formatAsPrimitive,
				onSelectedItemChange: handleSelectedItemChange,
				// NOTE: This makes the dropdown behave like a dropdown. Instead of calling `onSelect`
				// only when the actual selection changes, it will be fired upon any selection.
				selectedItem: null,
			});

		const toggleButtonRef = useRef<HTMLButtonElement>(null);
		const menuRef = useRef<HTMLUListElement>(null);

		const getMenuHeight = useCallback(
			() => menuRef.current?.clientHeight || menuRef.current?.scrollHeight || 0,
			[]
		);

		const { openDirection } = useOpenDirection({
			getRequiredHeight: getMenuHeight,
			shouldUpdate: isOpen,
			triggerRef: toggleButtonRef,
		});

		const toggleButtonProps = getToggleButtonProps({
			ref: ref && !isSplitButton ? mergeRefs([toggleButtonRef, ref]) : toggleButtonRef,
			tabIndex: 0,
		});

		const caretIconElement = (
			<Icon
				customStyle={caretIconStyle({ isOpen, isSplitButton, isSubtle })}
				size="sm"
				type="caretDown"
			/>
		);

		return (
			<div className={applyStyle([dropdownStyle, customStyle], { isOpen, isSubtle })}>
				<div className={applyStyle(buttonsWrapperStyle)}>
					<Button
						// NOTE: `onClick` and `ref` will get correctly overridden by `toggleButtonProps`.
						onClick={onClick}
						ref={ref}
						{...(!isSplitButton && toggleButtonProps)}
						customStyle={mergeStyles([
							dropdownButtonStyle({ isOpen, isSplitButton, isSubtle }),
							isSplitButton ? valueSplitButtonStyle({ isOpen }) : null,
						])}
						isBusy={isBusy}
						isDisabled={isDisabled}
						isOutlined={!isSubtle}
						size={isSubtle ? 'small' : 'medium'}
						variant={variant}
					>
						{formatter.format(value)}
						{!isBusy && !isSplitButton && caretIconElement}
					</Button>
					{isSplitButton && (
						<Button
							{...toggleButtonProps}
							customStyle={mergeStyles([
								dropdownButtonStyle({ isOpen, isSplitButton, isSubtle }),
								caretSplitButtonStyle,
							])}
							isDisabled={isBusy || isDisabled}
							isOutlined={!isSubtle}
							size={isSubtle ? 'small' : 'medium'}
							variant={variant}
						>
							{caretIconElement}
						</Button>
					)}
				</div>
				<Menu
					{...getMenuProps({ ref: menuRef })}
					customStyle={menuCustomStyle({ isOpen })}
					direction={openDirection}
					isEmbedded
					hasShadow
					hasPositionAbsolute
				>
					{items.map((item, index) => (
						<MenuItem
							{...getItemProps({ index, item })}
							isHighlighted={highlightedIndex === index}
							key={formatter.formatAsPrimitive(item)}
							size={isSubtle ? 'sm' : 'lg'}
						>
							{formatter.format(item)}
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
);

export const Dropdown = UntypedDropdown as <TItem extends Object>(
	props: DropdownProps<TItem> & RefAttributes<HTMLButtonElement>
) => ReactElement;
