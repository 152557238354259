import React, { forwardRef } from 'react';
import { includes, length } from 'ramda';
import { mapIndexed } from 'ramda-extension';
import PropTypes from 'prop-types';

import Accordion from '../Accordion';
import AccordionPanel from '../AccordionPanel';
import { onlySingleOpenReducer } from '../Accordion/utils';

const BasicAccordion = forwardRef(
	({ className, data, defaultOpenIndex, multipleOpenItems, isContentFormatted }, ref) => (
		<Accordion
			className={className}
			reducers={multipleOpenItems ? [] : [onlySingleOpenReducer]}
			defaultOpenIndex={defaultOpenIndex}
			ref={ref}
			indicesLength={length(data)}
		>
			{({ onClickHandler, openIndices }) =>
				mapIndexed(
					({ title, content, ...rest }, index) => (
						<AccordionPanel
							title={title}
							onClickHandler={onClickHandler(index)}
							isOpen={includes(index, openIndices)}
							key={index}
							isContentFormatted={isContentFormatted}
							{...rest}
						>
							{content}
						</AccordionPanel>
					),
					data
				)
			}
		</Accordion>
	)
);

BasicAccordion.displayName = 'forwardRef(BasicAccordion)';

BasicAccordion.propTypes = {
	/** Accordion root class name */
	className: PropTypes.string,
	/** Icon that shows when panel is closed */
	closedIconName: PropTypes.string,
	/** Accordion data structure */
	data: PropTypes.arrayOf(
		PropTypes.shape({
			/** Items title */
			title: PropTypes.node,
			/** Items content */
			content: PropTypes.node,
		})
	).isRequired,
	/** Accordion data structure */
	defaultOpenIndex: PropTypes.number,
	isContentFormatted: PropTypes.bool,
	/** Accordion multiple options open possibility **/
	multipleOpenItems: PropTypes.bool,
	/** Accordion data structure */
	openIconName: PropTypes.string,
	/** Reducers for accordion */
	reducers: PropTypes.array,
};

export default BasicAccordion;
