import { prepareMessages } from '@creditinfo-ui/messages/node';

export const m = prepareMessages('formatters', [
	['yes', 'Yes'],
	['no', 'No'],
	['open', 'Open'],
	['closed', 'Closed'],
	['on', 'On'],
	['off', 'Off'],
]);
